import React from 'react';
import {useSelector} from 'react-redux';
import {Building2, MapPin, FileText, IdCard, Mail} from 'lucide-react';

const MyOrganization = () => {
    const {currentUser} = useSelector((state) => state.users);

    if (!currentUser?.organization) {
        return (
            <div className="flex items-center justify-center min-h-[400px]">
                <div className="text-center">
                    <Building2 className="w-20 h-20 mx-auto mb-6 text-gray-400"/>
                    <h2 className="text-2xl font-bold text-gray-700 mb-3">No Organization Found</h2>
                    <p className="text-lg text-gray-500">
                        You are not currently associated with any organization.
                    </p>
                </div>
            </div>
        );
    }

    const {organization} = currentUser;

    const roleLabels = {
        ROLE_TEACHER: 'Teacher',
        ROLE_STUDENT: 'Student',
        ROLE_MODERATOR: 'Organization Moderator',
        ROLE_ORG_ADMIN: 'Organization Admin',
        ROLE_USER: 'User',
        ROLE_PARENT: 'Parent',
    };

    const userRole = currentUser.userRoles
        .filter((role) => role !== 'ROLE_USER')
        .map((role) => roleLabels[role] || role)
        .join(', ');

    return (

        <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-2xl shadow-lg border border-gray-200 p-8">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">My Organization</h1>
                    <span className="px-6 py-3 bg-blue-100 text-blue-700 rounded-full text-lg">
                            {userRole}
                        </span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="flex items-center space-x-4">
                        <Building2 className="w-8 h-8 text-blue-600"/>
                        <div>
                            <h3 className="text-lg font-medium text-gray-500">Organization Name</h3>
                            <p className="text-xl text-gray-900 font-semibold">{organization.name}</p>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <MapPin className="w-8 h-8 text-blue-600"/>
                        <div>
                            <h3 className="text-lg font-medium text-gray-500">Address</h3>
                            <p className="text-xl text-gray-900 font-semibold">{organization.address}</p>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <FileText className="w-8 h-8 text-blue-600"/>
                        <div>
                            <h3 className="text-lg font-medium text-gray-500">Description</h3>
                            <p className="text-xl text-gray-900 font-semibold">
                                {organization.description || 'No description available'}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <IdCard className="w-8 h-8 text-blue-600"/>
                        <div>
                            <h3 className="text-lg font-medium text-gray-500">Registration Number</h3>
                            <p className="text-xl text-gray-900 font-semibold">
                                {organization.registrationNo}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <Mail className="w-8 h-8 text-blue-600"/>
                        <div>
                            <h3 className="text-lg font-medium text-gray-500">Contact Email</h3>
                            <p className="text-xl text-gray-900 font-semibold">{organization.userName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default MyOrganization;
