import React, { useRef, useState, useEffect } from "react";
import { Select, Modal, Button, Spin, Pagination, Input, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { patch, get, post, del } from "../../util/APIUtils";
import { FaSearch } from "react-icons/fa";
import {
  uploadProfileImage,
  updateUserAvatar,
  updateUserProfile,
  addUserRoles,
  removeUserRoles, // Add this action
} from "../../actions/userActions";
import CameraIcon from "../../assets/camera.svg";
import UserIcon from "../../assets/default-user-icon.png";
import { API_BASE_URL, ACCESS_TOKEN } from "../../constantsNew";
import AuthImage from "../../components/AuthImage";
import RoleModal from "./RoleModal";
import { Confirm } from "react-st-modal";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    avatar: "",
    gender: "",
    birthday: "",
    type: "",
    school: "",
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  // Role-related states
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, size: 10, total: 0 });
  const hiddenFileInput = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [userRoles, setUserRoles] = useState(currentUser.userRoles);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setUserProfile({
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        email: currentUser.email || "",
        phone: currentUser.phone || "",
        avatar: currentUser.avatar || "",
        gender: currentUser.gender || "",
        birthday: currentUser.birthday || "",
        type: currentUser.type || "",
        school: currentUser.school || "",
      });
    }
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    setUpdateError(null);
    setUpdateSuccess(false);
    try {
      const updatedFields = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        phone: userProfile.phone,
        gender: userProfile.gender,
        birthday: userProfile.birthday,
      };

      const response = await patch(
        `/stridecal-backend/api/app-users/${currentUser.id}`,
        updatedFields
      );

      if (response) {
        console.log("Profile updated successfully:", response);
        // Update the Redux store with the new user data
        dispatch(updateUserProfile(response));
        // Update the local state with the response data
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          ...response,
        }));
        setUpdateSuccess(true);
        setTimeout(() => setUpdateSuccess(false), 3000); // Reset after 3 seconds
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setUpdateError("Failed to update profile. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleRoleChange = (value) => {
    console.log(value, currentUser.userRoles);
    setUserRoles(value);
    if (value.length > currentUser.userRoles.length) {
      let additionalRole = value.filter(
        (v) => !currentUser.userRoles.includes(v)
      );
      console.log(additionalRole);
      dispatch(
        addUserRoles(
          currentUser.id,
          additionalRole[0],
          setUserProfile,
          setUpdateSuccess
        )
      );
    } else {
      let roleToRemove = currentUser.userRoles.filter(
        (r) => !value.includes(r)
      );
      console.log("Role to remove", roleToRemove);
      dispatch(
        removeUserRoles(
          currentUser.id,
          roleToRemove[0],
          setUserProfile,
          setUpdateSuccess
        )
      );
    }
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        const response = await dispatch(uploadProfileImage(file));
        const avatarUrl = `/post-service/api/file-uploads/${response.id}`;
        await dispatch(updateUserAvatar(currentUser.id, avatarUrl));
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          avatar: avatarUrl,
        }));
      } catch (error) {
        console.error("Error uploading profile image:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      setUserProfile({
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        email: currentUser.email || "",
        phone: currentUser.phone || "",
        avatar: currentUser.avatar || "",
        gender: currentUser.gender || "",
        birthday: currentUser.birthday || "",
        type: currentUser.type || "",
        school: currentUser.school || "",
      });
    }
  }, [currentUser]);

  // const roles = [
  //   {
  //     label: "User",
  //     value: "ROLE_USER",
  //   },
  //   {
  //     label: "Student",
  //     value: "ROLE_STUDENT",
  //   },
  //   {
  //     label: "Teacher",
  //     value: "ROLE_TEACHER",
  //   },
  //   {
  //     label: "Parent",
  //     value: "ROLE_PARENT",
  //   },
  //   {
  //     label: "Organisation Admin",
  //     value: "ROLE_MODERATOR",
  //   },
  //   { label: "Admin", value: "ROLE_ADMIN" },
  // ];
  const roles = [
    { label: "User", value: "ROLE_USER" },
    { label: "Student", value: "ROLE_STUDENT" },
    { label: "Teacher", value: "ROLE_TEACHER" },
    { label: "Organization Moderator", value: "ROLE_MODERATOR" },
    { label: "Parent", value: "ROLE_PARENT" },
    { label: "Organisation Admin", value: "ROLE_ORG_ADMIN" },
  ];

  function filterRoles(roles, userRoles) {
    return roles.filter((role) => {
      // Exclude roles already present in userRoles
      if (userRoles.includes(role.value)) return false;

      // If "ROLE_STUDENT" exists in userRoles, exclude conflicting roles
      if (
        userRoles.includes("ROLE_STUDENT") &&
        [
          "ROLE_TEACHER",
          "ROLE_MODERATOR",
          "ROLE_PARENT",
          "ROLE_ORG_ADMIN",
        ].includes(role.value)
      ) {
        return false;
      }

      if (
        userRoles.includes("ROLE_PARENT") &&
        ["ROLE_STUDENT"].includes(role.value)
      ) {
        return false;
      }

      return true;
    });
  }

  const filteredRoles = filterRoles(roles, currentUser.userRoles);

  const handleOpenRoleModal = () => setIsRoleModalOpen(true);
  const handleCloseRoleModal = () => {
    setIsRoleModalOpen(false);
    setSelectedRole("");
    setOrganizations([]);
  };

  const fetchOrganizations = async (role, page = 0, size = 5) => {
    setLoadingOrganizations(true);
    try {
      const type =
        role === "ROLE_STUDENT" || role === "ROLE_TEACHER"
          ? "SCHOOL"
          : undefined;
      const response = await get(
        `/organization-service/api/organizations?page=${page}&size=${size}${
          type ? `&type=${type}` : ""
        }`
      );
      setOrganizations(response.content);
      setPagination({
        page: response.pageable.pageNumber,
        size: response.pageable.pageSize,
        total: response.totalElements,
      });
    } catch (error) {
      console.error("Error fetching organizations:", error);
    } finally {
      setLoadingOrganizations(false);
    }
  };

  const fetchSearchResults = async (query) => {
    setLoadingOrganizations(true);
    try {
      const response = await get(
        `/organization-service/api/organizations/search/findByName?name=${query}`
      );

      setOrganizations(response);
      setPagination({ page: 0, size: response.length, total: 0 });
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoadingOrganizations(false);
    }
  };

  // const handleSearchChange = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);

  //   if (query) {
  //     fetchSearchResults(query);
  //   } else {
  //     // If the search field is cleared, show default paginated results
  //     fetchOrganizations(selectedRole, pagination.page, pagination.size);
  //   }
  // };

  const handleSearchClear = () => {
    setSearchQuery("");
    fetchOrganizations(selectedRole, pagination.page, 5);
  };

  useEffect(() => {
    if (searchQuery.length == 0) {
      setTimeout(() => {
        fetchOrganizations(selectedRole, pagination.page, 5);
      }, 300);
    }
  }, [searchQuery]);

  // const handleOrganizationChange = (orgId) => {
  //   dispatch(
  //     addUserRoles(currentUser.id, selectedRole, (prevProfile) => ({
  //       ...prevProfile,
  //       type: selectedRole,
  //       school: orgId,
  //     }))
  //   );
  //   setIsRoleModalOpen(false);
  //   setSearchResults([]);
  //   setSearchQuery("");
  // };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    if (
      role === "ROLE_STUDENT" ||
      role === "ROLE_TEACHER" ||
      role === "ROLE_MODERATOR"
    ) {
      fetchOrganizations(role);
    }
  };

  const handleOrganizationChange = async (orgId, role) => {
    let reqType;
    if (role === "ROLE_TEACHER") {
      reqType = "OnboardTeacher";
    } else if (role === "ROLE_STUDENT") {
      reqType = "OnboardStudent";
    } else if (role === "ROLE_MODERATOR") {
      reqType = "OnboardToOrgAsModerator";
    }
    try {
      const response = await post(`/stridecal-backend/api/workflow-requests`, [
        {
          requestorId: currentUser.id,
          requestorName: `${currentUser.firstName} ${currentUser.lastName}`,
          requestType: reqType,
          // requestDescription: "JSON.stringify(form)",
          isApproved: false,
          orgId: orgId,
        },
      ]);
      if (response) {
        // await fetchOrganizations();
        message.success(
          "A request has been raised to change your role, Please wait for approval."
        );
        handleCloseRoleModal();
      }
    } catch (error) {
      message.error("Error Creating Organization Request");
    }
    // Dispatch action to assign the organization and role to the user
    // dispatch(
    //   addUserRoles(
    //     currentUser.id,
    //     selectedRole,
    //     (prevProfile) => ({
    //       ...prevProfile,
    //       type: selectedRole,
    //       school: orgId,
    //     }),
    //     setUpdateSuccess
    //   )
    // );
    handleCloseRoleModal();
  };

  const handlePaginationChange = (page) => {
    fetchOrganizations(selectedRole, page - 1, pagination.size); // Adjust page to be 0-indexed
  };

  const formatRole = (role) => {
    const roleMappings = {
      ROLE_MODERATOR: "Organization Moderator",
      ROLE_ORG_ADMIN: "Organization Admin",
      ROLE_USER: "User",
      ROLE_STUDENT: "Student",
      ROLE_TEACHER: "Teacher",
      ROLE_PARENT: "Parent",
    };
    return roleMappings[role] || role.replace("ROLE_", "").toLowerCase();
  };

  const handleRemoveRole = async (roleToRemove) => {
    const confirmDelete = await Confirm(
      `Are you sure you want to remove the role "${formatRole(roleToRemove)}"?`,
      "Confirm Role Removal"
    );

    if (confirmDelete) {
      try {
        await del(
          `/stridecal-backend/api/app-users/${currentUser.id}/roles/${roleToRemove}`
        );
        dispatch(
          removeUserRoles(
            currentUser.id,
            roleToRemove,
            setUserProfile,
            setUpdateSuccess
          )
        );
      } catch {
        message.error("Oops! Some Error Occured, Please Try Later");
      }
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="px-12 py-6 w-[800px] bg-white rounded-lg shadow-lg">
        <div>
          {/* Title and Button */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", margin: 0 }}>
              Update Your Profile
            </h1>
            <button
              onClick={handleOpenRoleModal}
              style={{
                padding: "8px 16px",
                fontSize: "0.875rem",
                cursor: "pointer",
                border: "1px solid #ddd",
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
              }}
            >
              Upgrade Role
            </button>
          </div>
          <div className="flex flex-wrap gap-2 mt-4">
            {currentUser?.userRoles?.map((role) => {
              const formattedRole = formatRole(role);
              return (
                <div
                  key={role}
                  className="flex items-center px-3 py-1 bg-gray-200 rounded-full text-sm text-gray-700"
                >
                  <span className="mr-2">{formattedRole}</span>
                  {role != "ROLE_USER" && (
                    <button
                      onClick={() => handleRemoveRole(role)}
                      className="text-gray-500 hover:text-red-500 focus:outline-none"
                    >
                      &times;
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <RoleModal
          isOpen={isRoleModalOpen}
          onClose={handleCloseRoleModal}
          roles={filteredRoles}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          organizations={organizations}
          setOrganizations={setOrganizations}
          loadingOrganizations={loadingOrganizations}
          pagination={pagination}
          fetchOrganizations={fetchOrganizations}
          fetchSearchResults={fetchSearchResults}
          handleRoleSelect={handleRoleSelect}
          handleOrganizationChange={handleOrganizationChange}
          setUserProfile={setUserProfile}
          setUpdateSucces={setUpdateSuccess}
        />

        {/* <div className="flex justify-end">
          <Button type="primary" onClick={handleOpenRoleModal}>
            Upgrade Role
          </Button>
        </div> */}
        <form onSubmit={handleSubmit}>
          <div>
            <div className="grid grid-cols-11 mt-4 mb-2 items-center justify-start gap-1">
              <h5 className="col-span-2">Personal Details</h5>
              <hr className="bg-gray-400 col-span-9 w-full" />
            </div>
            <div className="grid grid-cols-5 mt-6 gap-8 grid-flow-col">
              <div className="col-span-1 flex items-center justify-start flex-col gap-3">
                <div className="relative">
                  <AuthImage
                    imageurl={userProfile.avatar || UserIcon}
                    className="w-[70px] h-[70px] rounded-full object-cover"
                  />
                  {isUploading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                    </div>
                  )}
                  <label
                    htmlFor="avatarUpload"
                    className="absolute bottom-0 right-0 bg-gray-200 rounded-full p-1 cursor-pointer"
                  >
                    <img src={CameraIcon} alt="Upload" className="w-4 h-4" />
                  </label>
                </div>
                <p
                  className="text-[#4379EE] cursor-pointer"
                  onClick={handleClick}
                >
                  {isUploading ? "Uploading..." : "Upload Image"}
                </p>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
              <div className="col-span-2">
                <div className="mb-4">
                  <label className="mb-2 block">First Name</label>
                  <input
                    name="firstName"
                    value={userProfile.firstName}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="text"
                    placeholder="Enter your First Name"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Your Email</label>
                  <input
                    name="email"
                    value={userProfile.email}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="email"
                    placeholder="Enter your Email"
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Date of Birth</label>
                  <input
                    name="birthday"
                    value={userProfile.birthday}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="date"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="mb-4">
                  <label className="mb-2 block">Last Name</label>
                  <input
                    name="lastName"
                    value={userProfile.lastName}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="text"
                    placeholder="Enter your last name"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Phone Number</label>
                  <input
                    name="phone"
                    value={userProfile.phone}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="tel"
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Gender</label>
                  <select
                    name="gender"
                    value={userProfile.gender}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-6">
            <div className="w-2/3">
              {/* <label className="mb-2 block">Upgrade Role</label> */}
              {/* <div className="card flex justify-content-center">
                  <MultiSelect value={currentUser.userRoles} onChange={handleInputChange} options={roles} optionLabel="name" 
                        placeholder="Select Cities" maxSelectedLabels={3} className="w-full md:w-20rem" />
              </div> */}
              {/* <Select
                options={roles}
                name="role"
                mode="multiple"
                value={userRoles}
                onChange={handleRoleChange}
                className="py-1 px-1 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
              /> */}
              {/* <option value="">Select role</option>
                <option value="ROLE_USER">User</option>
                <option value="ROLE_STUDENT">Student</option>
                <option value="ROLE_TEACHER">Teacher</option>
                <option value="ROLE_PARENT">Parent</option>
              </Select> */}
            </div>
            <button
              type="submit"
              className={`py-3 text-[0.8rem] px-16 rounded-lg ${
                updateSuccess
                  ? "bg-green-500"
                  : isUpdating
                  ? "bg-gray-400"
                  : "bg-[#4880FF]"
              } text-white relative`}
              disabled={isUpdating}
            >
              {isUpdating
                ? "Updating..."
                : updateSuccess
                ? "Updated!"
                : "Update Profile"}
              {updateSuccess && (
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2">
                  ✓
                </span>
              )}
            </button>
          </div>
          {updateError && <p className="text-red-500 mt-2">{updateError}</p>}
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
