import React, { useState, useEffect, useRef } from "react";
import { Input, Skeleton, Form, message, Alert, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { get } from "../../util/APIUtils";
import { useDispatch, useSelector } from "react-redux";
import { BiPlus } from "react-icons/bi";
import ProgramModal from "../../components/AcademicProgram/ProgramModal";
import {
  fetchPrograms,
  saveProgram,
  updateProgram,
  deleteProgram,
} from "../../actions/academicProgramActions";
import { OrganizationSection } from "./organizationSection";

const Programs = (props) => {
  const [loading, setLoading] = useState(false);
  const { market } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useSelector((state) => state.users.currentUser);
  const [paginatedOrganizations, setPaginatedOrganizations] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false);
  const [program, setProgram] = useState({
    id: "",
    programName: "",
    description: "",
    duration: "",
    level: "",
    mode: "",
    type: "",
    department: "",
    faculty: "",
    university: "",
    country: "",
    city: "",
    website: "",
    email: "",
    phone: "",
    address: "",
    postalCode: "",
    state: "",
    countryId: "",
    cityId: "",
    departmentId: "",
    facultyId: "",
    universityId: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    createdBy: "",
    updatedBy: "",
    deletedBy: "",
    status: "",
    isDeleted: false,
    isPublished: false,
    isFeatured: false,
    isVerified: false,
    isApproved: false,
    isRejected: false,
    isPending: false,
    isDraft: false,
    isArchived: false,
    isBlocked: false,
    isSpam: false,
    isFlagged: false,
    isReported: false,
    isClosed: false,
    isBanned: false,
    isHidden: false,
    isPublic: false,
    isPrivate: false,
    isProtected: false,
    isPaid: false,
    isFree: false,
    isFeaturedHome: false,
    isFeaturedCategory: false,
    isFeaturedSubCategory: false,
    isFeaturedPage: false,
    isFeaturedPost: false,
    isFeaturedEvent: false,
    isFeaturedCourse: false,
    isFeaturedInternship: false,
    schedules: [],
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const pageSize = 20; // Number of organizations per page
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(true);
  const { programs, pageable } = useSelector((state) => state.academicPrograms);

  const handleEditorChange = (data) => {
    setProgram((prevState) => ({
      ...prevState,
      description: data,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProgram((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await getBase64(file);
        return {
          default: base64,
        };
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleSave = (e, publish) => {
    e.preventDefault();
    let programData = { ...program, isPublished: publish };
    if (program.id) {
      dispatch(updateProgram(program.id, programData)).then(() => {
        message.success("Program updated successfully");
        setShowCreateProgramModal(false);
        // setCurrentStep(0);
        dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
        // form.resetFields();
      });
    } else {
      if (!program.programName) {
        return Alert("Please enter program title");
      }
      dispatch(saveProgram(programData)).then(() => {
        message.success("Program saved successfully");
        setShowCreateProgramModal(false);
        // setCurrentStep(0);
        dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
        // form.resetFields();
      });
    }
  };

  const containerRef = useRef(null);

  const handleEdit = (e, program) => {
    e.stopPropagation();
    setProgram(program);
    setShowCreateProgramModal(true);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    dispatch(deleteProgram(id)).then(() => {
      Alert("Program deleted successfully");
      dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
    });
  };

  const fetchOrganisations = async (orgId, page, pageSize = 20) => {
    if (!hasMore || loading) return;

    setLoading(true);
    try {
      const url = market
        ? `/organization-service/api/organizations?page=${
            page - 1
          }&size=${pageSize}`
        : `/organization-service/api/organizations/${orgId}`;

      const response = await get(url);

      if (response) {
        const newOrganizations = response.content || [];
        setPaginatedOrganizations(
          market ? (prev) => [...prev, ...newOrganizations] : [response]
        );
        setTotalRecords(market ? response.totalElements : 1);
        setHasMore(page < response.totalPages);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const handleScroll = () => {
    if (!containerRef.current || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchOrganisations(currentUser.organization, nextPage, pageSize);
        return nextPage;
      });
    }
  };

  useEffect(() => {
    if (
      !market &&
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      fetchOrganisations(currentUser.organization.id, currentPage, pageSize);
    } else {
      fetchOrganisations(currentUser?.organization?.id, currentPage, pageSize);
    }
  }, [currentUser, market, currentPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const createProgram = () => {
    setProgram({});
    setShowCreateProgramModal(true);
  };

  const SkeletonCard = () => (
    <Card className="shadow-lg h-full">
      <Skeleton active avatar paragraph={{ rows: 4 }} />
      <div className="mt-4">
        <Skeleton.Button active size="large" shape="round" block={true} />
      </div>
    </Card>
  );

  return (
    <div
      className="px-4 w-full"
      style={{ height: "calc(100vh - 120px)", overflowY: "auto" }}
      ref={containerRef}
      onScroll={handleScroll}
    >
      <div className=" top-0 bg-[#E8E8FF] pt-2 w-full pb-2">
        <div className="flow-root flex">
          <h1 className="text-4xl font-bold mb-8 text-start">
            {market ? "Marketplace" : "Academic Programs"}
          </h1>
          {!market && (
            <div className="float-right h-fit min-h-full flex justify-end">
              <button
                onClick={createProgram}
                className="p-2 bg-blue-600 text-white rounded flex items-center"
              >
                <BiPlus size={20} className="mr-2" /> Create program
              </button>
            </div>
          )}
        </div>

        <div className="mb-6">
          <Input
            placeholder="Search programs or organizations"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full max-w-md mx-auto"
          />
        </div>
      </div>
      {/* <h1 className="text-4xl font-bold mb-8 text-center">Marketplace</h1>
      <Search
        placeholder="Search organizations or programs"
        enterButton="Search"
        size="large"
        className="mb-8"
      /> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* {loading
          ? [...Array(6)].map((_, index) => <SkeletonCard key={index} />)
          : paginatedOrganizations.map((org) => (
              <OrganizationSection
                key={org.id}
                organization={org}
                market={market}
              />
            ))} */}
        {paginatedOrganizations.map((org) => (
          <OrganizationSection
            key={org.id}
            organization={org}
            market={market}
          />
        ))}
        {loading &&
          [...Array(6)].map((_, index) => <SkeletonCard key={index} />)}
      </div>
      <ProgramModal
        showCreateProgramModal={showCreateProgramModal}
        setShowCreateProgramModal={setShowCreateProgramModal}
        program={program}
        setProgram={setProgram}
        handleChange={handleChange}
        handleEditorChange={handleEditorChange}
        uploadPlugin={uploadPlugin}
        handleSave={handleSave}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        form={form}
        Form={Form}
      />
    </div>
    // <div
    //   className="px-4 overflow-y-auto w-full flex flex-col items-center"
    //   // className=" "
    //   style={{ height: "calc(100vh - 120px)" }}
    //   ref={containerRef}
    //   onScroll={handleScroll}
    // >
    //   <div className="sticky top-0 bg-[#E8E8FF] z-10 pt-2 w-[70%]">
    //     <div className="flow-root flex">
    //       <h2 className="float-left text-2xl font-bold mb-4 flex justify-between">
    //         {market ? "Marketplace" : "Academic Programs"}
    //       </h2>
    //       {!market && (
    //         <div className="float-right h-fit min-h-full flex justify-end">
    //           <button
    //             onClick={createProgram}
    //             className="p-2 bg-blue-600 text-white rounded flex items-center"
    //           >
    //             <BiPlus size={20} className="mr-2" /> Create program
    //           </button>
    //         </div>
    //       )}
    //     </div>

    //     <div className="mb-6">
    //       <Input
    //         placeholder="Search programs or organizations"
    //         prefix={<SearchOutlined />}
    //         onChange={(e) => setSearchTerm(e.target.value)}
    //         className="w-full max-w-md mx-auto"
    //       />
    //     </div>
    //   </div>
    //   <div
    //     className="w-[70%]"
    //     // grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4"
    //   >
    //     {paginatedOrganizations.map((org) => (
    //       <OrganizationSection
    //         key={org.id}
    //         organization={org}
    //         market={market}
    //       />
    //     ))}
    //   </div>

    //   {loading && (
    //     <div className="text-center">
    //       <Skeleton.Input
    //         active
    //         style={{
    //           width: "65vw",
    //           height: "20vh",
    //         }}
    //       />
    //     </div>
    //   )}

    // </div>
  );
};

export default Programs;
