import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "./loader";
import SuccessPage from "./successPage";
import { post } from "../../../util/APIUtils";
import { useSelector } from "react-redux";
import FailPage from "./failPage";

const PaymentPage = () => {
  const orgData = {
    name: "Tech Academy",
    program: "Full-Stack Developer Bootcamp",
    amount: 280,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const currentUser = useSelector((state) => state.users.currentUser);
  const location = useLocation();
  const { organizationName, academicProgram } = location.state || {};

  const plans = [
    { label: "6 Months", duration: 6 },
    { label: "12 Months", duration: 12 },
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handlePayment = async () => {
    if (!selectedPlan) return;

    const now = Math.floor(Date.now() / 1000);
    const totalMonths = selectedPlan.duration;
    const expireBy = now + totalMonths * 30 * 24 * 60 * 60;

    const subscriptionObject = {
      totalCount: totalMonths,
      quantity: 1,
      customer_notify: 1,
      start_at: now,
      expire_by: expireBy,
      programId: academicProgram.id,
    };

    setIsLoading(true);
    try {
      const resp = await post(
        "/payment-service/api/subscriptions",
        subscriptionObject
      );

      const options = {
        key: "rzp_test_ZXkO04p3P8jsve", // Razorpay Key ID
        subscription_id: resp.id,
        name: academicProgram?.programName,
        description: `Academic program under ${organizationName}`,
        handler: async function (response) {
          await post(
            `/organization-service/api/academicPrograms/${academicProgram.id}/subscribe`
          );
          console.log("Payment successful", response);
          setIsSuccess(true);
        },
        prefill: {
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          email: `${currentUser.email}`,
          contact: `${currentUser.phone}`,
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          escape: false,
          ondismiss: function () {
            console.log("Payment modal closed by user");
            setIsFail(true);
          },
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Payment Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;
  if (isSuccess) return <SuccessPage />;
  if (isFail) return <FailPage />;

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-300 w-full max-w-2xl">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          Payment Summary
        </h2>
        <div className="text-gray-600 mb-6">
          <p>
            <span className="font-medium">Organization:</span>{" "}
            {organizationName}
          </p>
          <p>
            <span className="font-medium">Program:</span>{" "}
            {academicProgram?.programName}
          </p>
        </div>
        <div className="bg-gray-100 p-4 rounded-md text-gray-800 text-start mb-6">
          <h3 className="text-md font-semibold mb-1">Amount</h3>
          <p className="text-lg font-bold mb-3">
            ₹{orgData.amount}{" "}
            {/* <span className="text-sm text-gray-500">(Per Month)</span> */}
          </p>
          <h3 className="text-md font-semibold mb-1">Subscription Frequency</h3>
          <p className="text-lg font-bold">
            Monthly
            {/* <span className="text-sm text-gray-500">(Per Month)</span> */}
          </p>
        </div>
        <h2 className="text-xl font-semibold text-gray-800 mb-1">
          Select a Subscription Period:
        </h2>

        <div className="grid grid-cols-2 gap-4 mb-6">
          {plans.map((plan) => (
            <div
              key={plan.label}
              onClick={() => handlePlanSelect(plan)}
              className={`p-4 rounded-lg text-center cursor-pointer transition-all duration-300 
                border-2 ${
                  selectedPlan?.duration === plan.duration
                    ? "border-blue-600 bg-gradient-to-r from-blue-200 to-blue-100 shadow-lg"
                    : "border-gray-300 hover:border-blue-400 hover:shadow-md"
                }
              `}
            >
              <h3 className="text-lg font-bold">{plan.label}</h3>
              {/* <p className="text-sm text-darkgray-600">
                ₹{orgData.amount} per month
              </p>
              <p className="text-sm text-darkgray-600">
                for {plan.duration} months
              </p> */}
            </div>
          ))}
        </div>
        <button
          onClick={handlePayment}
          disabled={!selectedPlan}
          className={`w-full py-3 rounded-lg text-white font-semibold transition-all duration-300 
            ${
              selectedPlan
                ? "bg-blue-600 hover:bg-blue-700"
                : "bg-gray-400 cursor-not-allowed"
            }
          `}
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

export default PaymentPage;
