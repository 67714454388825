import React, { useEffect, useState } from "react";
import { Table, Button, Pagination } from "antd";
import { AiOutlineCheck, AiOutlineDelete, AiOutlineX } from "react-icons/ai";
import { del, get, patch, post, put } from "../../util/APIUtils";
import { useSelector } from "react-redux";
import { Confirm } from "react-st-modal";
import moment from "moment";

const Subscriptions = () => {
  const { currentUser } = useSelector((state) => state.users);
  const [wfReqList, setWfReqList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser?.id) {
      fetchSubscriptions(currentUser.id);
    }
  }, [currentUser]);

  const fetchSubscriptions = async () => {
    setLoading(true);
    setWfReqList([]);
    try {
      const response = await get(
        // `/organization-service/api/academicPrograms/mySubscriptions`
        `/payment-service/api/subscriptions`
      );
      // const revResp = response.slice().reverse();
      response.forEach(async (sub, index) => {
        const test = {
          key: sub.id || index,
          // name: item.programName,
          createdAt: moment.unix(sub.createdAt).format("DD MMM, YYYY"),
          subPeriod: sub.totalCount,
          status: sub.status,
          rawData: sub,
        };
        if (sub.programId) {
          const resp = await get(
            `/organization-service/api/academicPrograms/${sub.programId}`
          );
          test.name = resp.programName;
          console.log("test--->>>", response[index]);
        }
        setWfReqList((prev) => [...prev, test]);
      });
    } catch (error) {
      console.error("Error Fetching Workflow Requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSub = async (record) => {
    const confirmCancel = await Confirm(
      `Are you sure you want to cancel your subscription?`
      // You will still have access to your academic program for the duration of the already paid period.
    );

    if (confirmCancel) {
      try {
        await post(
          `/organization-service/api/academicPrograms/${record.rawData.programId}/unsubscribe`
        );

        fetchSubscriptions();
      } catch (error) {
        console.error("Error cancelling subscription:", error);
      }
    }
  };

  const columns = [
    {
      title: "Program Name",
      dataIndex: "name",
      key: "name",
      // render: (text) => (
      //   <span className="font-semibold text-gray-700">{text}</span>
      // ),
    },
    {
      title: "Start Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Subscription Period",
      dataIndex: "subPeriod",
      key: "subPeriod",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          className={`px-2 py-1 text-sm font-medium rounded-full ${
            text === "Approved"
              ? "text-green-700 bg-green-100"
              : "text-yellow-700 bg-yellow-100"
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="flex space-x-2">
          <Button
            type="primary"
            // icon={<AiOutlineCheck className="mr-1" />}
            className="flex items-center bg-green-500 hover:bg-green-600 border-none"
            onClick={() => handleCancelSub(record)}
          >
            Cancel Subscription
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="container h-[78vh] mx-auto p-6 bg-white rounded-lg shadow-lg overflow-y-auto">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">
        My Subscriptions
      </h2>
      <Table
        columns={columns}
        dataSource={wfReqList}
        pagination={false}
        loading={loading}
        rowClassName="bg-gray-50 hover:bg-gray-100 transition duration-200 ease-in-out"
        className="mb-4"
      />
    </div>
  );
};

export default Subscriptions;
