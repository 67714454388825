import React, { useEffect, useState } from "react";
import { Modal, Select, Input, Spin, Pagination, message } from "antd";
import { FaPlus, FaSearch, FaSpinner } from "react-icons/fa";
import { get, patch, post } from "../../util/APIUtils";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";

import {
  uploadProfileImage,
  updateUserAvatar,
  updateUserProfile,
  addUserRoles,
  removeUserRoles, // Add this action
} from "../../actions/userActions";

const { Option } = Select;

const RoleModal = ({
  isOpen,
  onClose,
  roles,
  selectedRole,
  setSelectedRole,
  organizations,
  setOrganizations,
  loadingOrganizations,
  pagination,
  fetchOrganizations,
  fetchSearchResults,
  handleRoleSelect,
  handleOrganizationChange,
  setUserProfile,
  setUpdateSuccess,
}) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const { currentUser } = useSelector((state) => state.users);
  const [organisationTypes, setOrganisationTypes] = useState([]);
  const [form, setForm] = useState({
    organisationType: "",
    name: "",
    address: "",
    registrationNo:""
  });
  const [formErrors, setFormErrors] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState();
  const [studentsList, setStudentsList] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [viewingStudents, setViewingStudents] = useState(false);

  useEffect(() => {
    if (
      selectedRole === "ROLE_PARENT" &&
      selectedOrganisation &&
      selectedOrganisation.length > 0
    ) {
      fetchStudentsUnderOrg(selectedOrganisation);
    }
  }, [selectedOrganisation]);

  const fetchStudentsUnderOrg = async (orgId, page = 0) => {
    setLoadingStudents(true);

    try {
      const response = await get(
        `/stridecal-backend/api/app-users/search/students?organizationId=${orgId}&page=${page}&size=20`
      );
      // console.log("SelectedOrganisation--->>>", response, viewingStudents);
      setStudentsList(response);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    } finally {
      setLoadingStudents(false);
    }
  };

  const handleAddStudent = (student) => {
    let studArr = [...selectedStudents, student];
    setSelectedStudents(studArr);
  };
  const handleBackToOrganizations = () => {
    setViewingStudents(false);
  };

  const handleReqParent = async () => {
    let data = [];
    selectedStudents.map((stud) => {
      data.push({
        requestorId: currentUser.id,
        requestorName: `${currentUser.firstName} ${currentUser.lastName}`,
        requestType: "OnboardParent",
        // requestDescription: JSON.stringify(form),
        // isApproved: false,
        childId: stud.id,
      });
    });
    try {
      const response = await post(
        `/stridecal-backend/api/workflow-requests`,
        data
      );
      if (response) {
        // await fetchOrganizations();
        message.success(
          "A request has been raised to change your role, Please wait for approval."
        );
        onClose();
      }
    } catch (error) {
      message.error("Error Creating Organization Request");
    }
    // Dispatch action to assign the organization and role to the user
    // dispatch(
    //   addUserRoles(
    //     currentUser.id,
    //     selectedRole,
    //     (prevProfile) => ({
    //       ...prevProfile,
    //       type: selectedRole,
    //       school: orgId,
    //     }),
    //     setUpdateSuccess
    //   )
    // );
    onClose();
  };
  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      fetchSearchResults(query);
    } else {
      // If the search field is cleared, show default paginated results
      fetchOrganizations(selectedRole, pagination.page, pagination.size);
    }
  };

  const handlePaginationChange = (page) => {
    fetchOrganizations(selectedRole, page - 1, pagination.size); // Adjust for 0-indexed pages
  };

  const handleSearchClear = () => {
    setSearchQuery("");
    fetchOrganizations(selectedRole, pagination.page, 5);
  };

  useEffect(() => {
    fetchOrganisationTypes();
  }, []);

  const CreateOrganisationRequest = async () => {
    try {
      const response = await post(`/stridecal-backend/api/workflow-requests`, [
        {
          requestorId: currentUser.id,
          requestorName: `${currentUser.firstName} ${currentUser.lastName}`,
          requestType: "CreateOrganization",
          requestDescription: JSON.stringify(form),
          isApproved: false,
        },
      ]);
      if (response) {
        onClose();
        setForm({
          organisationType: "",
          name: "",
          address: "",
          registrationNo:""
        });
        message.success(
          "A request has been raised to change your role, Please wait for approval."
        );
      }
    } catch (error) {
      message.error("Error Creating Organization Request, Please Try Again!");
    }
  };

  const fetchOrganisationTypes = async () => {
    try {
      const response = await get(
        `/organization-service/api/organizations/search/types`
      );
      if (response) {
        setOrganisationTypes(response);
      }
    } catch (error) {
      console.log("Error Fetching OrganisationTypes");
    }
  };

  const handleFieldChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!form.organisationType) errors.organisationType = "Type is required.";
    if (!form.name) errors.name = "Name is required.";
    if (!form.address) errors.address = "Address is required.";
    if (!form.registrationNo) errors.registrationNo = "Registration Number is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setSaveLoading(true);
      try {
        await CreateOrganisationRequest();
        setOpenCreateModal(false);
        await fetchOrganizations();
      } catch (error) {
        console.error("Error creating organization:", error);
      } finally {
        setSaveLoading(false);
      }
    }
  };

  const saveRoleUser = async () => {
    try {
      await dispatch(
        addUserRoles(
          currentUser.id,
          "ROLE_USER",
          setUserProfile,
          setUpdateSuccess
        )
      );
      onClose();
    } catch {
      message.error("Oops! Request Failed, Please Try Again Later!");
    }
  };

  return (
    <Modal
      title="Upgrade Role"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      className="rounded-lg shadow-lg"
      bodyStyle={{ height: "450px", overflowY: "auto" }}
    >
      <div className="mb-4 mt-3">
        <h2 className="text-start">Select Role</h2>
        <Select
          options={roles}
          name="role"
          onChange={handleRoleSelect}
          value={selectedRole}
          placeholder="Select a role"
          className="w-full rounded border-gray-300 shadow-sm focus:ring focus:ring-blue-500"
        />
      </div>

      {(selectedRole === "ROLE_STUDENT" ||
        selectedRole === "ROLE_TEACHER" ||
        selectedRole === "ROLE_MODERATOR" ||
        selectedRole === "ROLE_ORG_ADMIN" ||
        selectedRole === "ROLE_PARENT") &&
        !viewingStudents &&
        createModal == false && (
          <div className="mb-4 flex justify-between items-center">
            <div
              className={`relative ${
                selectedRole === "ROLE_MODERATOR" ? "w-[60%]" : "w-[100%]"
              }`}
            >
              <Input
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search for organizations"
                className="rounded-lg border-gray-300"
              />
              <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              {searchQuery && (
                <button
                  onClick={handleSearchClear}
                  className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-500"
                >
                  Clear
                </button>
              )}
            </div>
            <div>
              {selectedRole === "ROLE_ORG_ADMIN" && (
                <button
                  onClick={() => setCreateModal(true)}
                  className="flex items-center gap-1 justify-center text-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  <FaPlus /> Create
                </button>
              )}
            </div>
          </div>
        )}

      {loadingOrganizations && !viewingStudents ? (
        <div className="flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <>
          {createModal ? (
            <>
              <h2 className=" font-semibold mt-6 mb-3 text-start">
                Create Organisation
              </h2>
              <div className="py-2 rounded-lg w-full  items-center justify-center mb-2">
                <label className="whitespace-nowrap text-sm w-full">
                  Organisation Type:
                </label>
                <Select
                  name="organisationType"
                  value={form.organisationType}
                  onChange={(value) =>
                    handleFieldChange("organisationType", value)
                  }
                  className="mr-2 w-full"
                >
                  {organisationTypes.map((type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
                {formErrors.organisationType && (
                  <div className="text-red-500 text-sm">
                    {formErrors.organisationType}
                  </div>
                )}
              </div>

              <div className=" py-2 rounded-lg w-full items-center justify-center mb-2">
                <label className="whitespace-nowrap text-sm w-full">
                  Organisation Name:
                </label>
                <Input
                  name="name"
                  value={form.name}
                  onChange={(e) => handleFieldChange("name", e.target.value)}
                  className="mr-2 w-full"
                />
                {formErrors.name && (
                  <div className="text-red-500 text-sm">{formErrors.name}</div>
                )}
              </div>
              <div className="py-2 rounded-lg w-full  mb-2">
                
                <label className="whitespace-nowrap text-sm align-top w-full mt-2 ">
                  Registration Number:
                </label>
                <div className="mr-2 w-full inline-block ">
                  <Input
                  name="registrationNo"
                  value={form.registrationNo}
                  onChange={(e) => handleFieldChange("registrationNo", e.target.value)}
                  className="mr-2 w-full"
                />
                
                {formErrors.registrationNo && (
                  <div className="text-red-500 text-sm">{formErrors.registrationNo}</div>
                )}
                </div>
              </div>
              <div className="py-2 rounded-lg w-full  mb-2">
                <label className="whitespace-nowrap text-sm align-top w-full mt-2">Address:</label>
                <div className="mr-2 w-full inline-block">
                  <TextArea
                  name="address"
                  value={form.address}
                  onChange={(e) => handleFieldChange("address", e.target.value)}
                  className="mr-2 w-full"
                />
                {formErrors.address && (
                  <div className="text-red-500 text-sm">
                    {formErrors.address}
                  </div>
                )}
                </div>
              </div>

              <div className="flex justify-end gap-4 mt-6">
                <button
                  onClick={() => setCreateModal(false)}
                  className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
                  disabled={saveLoading}
                >
                  {saveLoading ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Saving...
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              {!viewingStudents && (
                <div className="overflow-y-auto" style={{ maxHeight: "280px" }}>
                  {selectedRole && (
                    <ul className="mb-4 w-full space-y-2">
                      {organizations.map((org) => (
                        <li
                          key={org.id}
                          className="p-2 bg-white border rounded shadow-sm hover:bg-blue-50 hover:shadow-md transition-all cursor-pointer"
                          onClick={() =>
                            selectedRole === "ROLE_PARENT"
                              ? (setSelectedOrganisation(org.id),
                                setViewingStudents(true))
                              : handleOrganizationChange(org.id, selectedRole)
                          }
                        >
                          <p className="font-semibold text-gray-800">
                            {org.name}
                          </p>
                          <p className="text-sm text-gray-500">{org.address}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      {selectedRole === "ROLE_PARENT" && viewingStudents && (
        <>
          <button
            onClick={handleBackToOrganizations}
            className="px-4 py-2 mb-4 bg-gray-200 text-gray-600 rounded hover:bg-gray-300"
          >
            Back to Organizations
          </button>
          {loadingStudents ? (
            <div className="flex items-center justify-center">
              <Spin />
            </div>
          ) : studentsList.length > 0 ? (
            <ul className="overflow-y-auto" style={{ maxHeight: "280px" }}>
              {studentsList.map((student) => (
                <li
                  key={student.id}
                  className="p-2 bg-white border rounded shadow-sm cursor-pointer"
                  onClick={() => handleAddStudent(student)}
                >
                  <p className="font-semibold text-gray-800">
                    {student.firstName} {student.lastName}
                  </p>
                  <p className="text-sm text-gray-500">{student.email}</p>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center text-gray-500">
              No students in organization
            </div>
          )}
        </>
      )}

      {selectedRole &&
        selectedRole != "ROLE_USER" &&
        createModal == false &&
        !viewingStudents && (
          <Pagination
            current={pagination.page + 1}
            pageSize={pagination.size}
            total={pagination.total}
            onChange={handlePaginationChange}
            className="mt-4"
          />
        )}
      {selectedRole === "ROLE_USER" && (
        <div className="flex justify-end">
          <button onClick={saveRoleUser}>Save</button>
        </div>
      )}
      {viewingStudents && selectedStudents.length > 0 && (
        <div className="flex justify-end">
          <button onClick={handleReqParent}>Save</button>
        </div>
      )}
    </Modal>
  );
};

export default RoleModal;
