import React, {useEffect, useState} from "react";
import {
  Routes,
  Route,
  useNavigate,
  useSearchParams,
  Outlet,
} from "react-router-dom";
import "./App.css";
import Login from "./page/Login";
import CreateAccount from "./page/CreateAccount";
import LiveNotes from "./page/LiveNotes";
import Dashboard from "./page/Dashboard";
import Planner from "./page/Planner";
import Forum from "./page/Forum";
import Program from "./page/Program";
import Reward from "./page/Reward";
import LoadingIndicator from "./components/LoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getCurrentUserDetails } from "./util/APIUtils";
import { setCurrentUser, setAuthenticated } from "./actions/postActions";
import {ACCESS_TOKEN, USER} from "./constantsNew";
import { Alert } from "react-st-modal";
import Signup from "./page/signup/Signup";
import Settings from "./page/settings/settings";
import TeachersDashboard from "./page/TeachersDashboard";

import MyContent from "./page/MyContent";
import OnboardingPage from "./page/OnboardingScreen";
import SetupModal from "./components/Login/SetupModal";
import ProjectDetails from "./page/ProjectDetails";
import AcademicPrograms from "./page/AcademicPrograms/AcademicPrograms";
import ProgramSchedules from "./page/ProgramSchedules";
import {
  fetchUserTimezone,
  fetchNotifications,
  fetchUnreadNotificationCount,
} from "./actions/userActions"; // New import

import AcademicProgramsDetails from "./page/AcademicPrograms/AcademicProgramDetails";
import WebsocketService from "./common/WebsocketService";
import { FETCH_TRANSCRIPTION_JOB_STATUS } from "./constants/userConstants";
import {
  NEW_NOTIFICATIONS,
  NEW_NOTIFICATIONS_ARRAY,
  REFRESH_CHAT,
  TYPING_STATUS,
  TYPING_STATUS_CLEAR,
} from "./actions/chatActions";

import Sidebar from "./components/Layout/Sidebar";
import TopBar from "./components/Layout/TopBar";
import DashboardArea from "./components/Dashboard/DashboardArea";
import ChatArea from "./components/Forum/ChatArea";
import { notification } from "antd";
import Administration from "./page/Administration/administration";
import UserProfile from "./page/settings/UserProfile";
import TimezoneSettings from "./page/settings/TimezoneSettings";
import SecuritySettings from "./page/settings/SecuritySettings";
import NotificationPreferences from "./page/settings/NotificationPreferences";
import PrivacySettings from "./page/settings/PrivacySettings";
import { OrganizationSettings } from "./page/settings/OrganizationSettings";
import Programs from "./page/programs/programs";
import Approvals from "./page/Administration/Approvals";
import PaymentPage from "./page/programs/payment/paymentPage";
import { ProgramDetails } from "./page/programs/programDetails";

const App = () => {

  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const [searchParams] = useSearchParams();
  console.log("searchParams", searchParams);
  const token = searchParams.get("token");
  // console.log("Token===>",token)
  const currentUser = useSelector((state) => state.users.currentUser);
  const [state, setState] = useState({
    authenticated: false,
    currentUser: null,
    loading: true,
  });
  const [showModal, setShowModal] = useState(false);
  const [currentRoleView, setCurrentRoleView] = useState("");

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (logoutModalVisible) {
        localStorage.setItem('forceLogout', 'true');
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    const forceLogout = localStorage.getItem("forceLogout");
    if (forceLogout === 'true') {
      setLogoutModalVisible(true);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [logoutModalVisible]);

  useEffect(() => {
    if (currentUser && currentUser.userRoles)
      setCurrentRoleView(currentUser.userRoles[0]);
  }, [currentUser]);
  console.log("wow>>>", currentRoleView);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    localStorage.getItem("collapsed")
      ? localStorage.getItem("collapsed") === "true"
      : false
  );
  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        setState({
          currentUser: response,
          authenticated: true,
          loading: false,
        });
        dispatch(setCurrentUser(response));
        dispatch(setAuthenticated(true));
        dispatch(fetchUserTimezone(response.userName));
        dispatch(fetchNotifications());
        dispatch(fetchUnreadNotificationCount());
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        navigate("/login");
      });
  };

  useEffect(() => {
    if (token) {
      console.log("Token===>", token);
      localStorage.setItem(ACCESS_TOKEN, token);
      // console.log(JSON.stringify(response));
      Alert("You're successfully logged in!");
      const timer = setTimeout(() => {
        navigate("/livenotes");
        navigate(0);
      }, 500);
    }
  }, [token]);

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      WebsocketService.connect(() => {
        WebsocketService.subscribe(
          `/topic/add/post/${currentUser.email}`,
          (message) => {
            //const data = response.body;//JSON.parse(response.body);
            console.log("Message received via websocket=====>", message);
            if (message?.command == "TranscriptionJobCompleted") {
              dispatch({
                type: FETCH_TRANSCRIPTION_JOB_STATUS,
                payload: message,
              });
            } else {
            }
          }
        );
        WebsocketService.subscribe(
          `/topic/chat/${currentUser.id}`,
          (message) => {
            //const data = response.body;//JSON.parse(response.body);
            console.log("Message received via websocket=====>", message);
            if (message.type === "TypingStatus") {
              dispatch({ type: TYPING_STATUS, payload: message });
            } else if (message.type === "TypingStatusClear") {
              dispatch({ type: TYPING_STATUS_CLEAR, payload: message });
            } else {
              dispatch({
                type: REFRESH_CHAT,
                payload: { chatId: message.chat.id, message },
              });
            }
          }
        );
        WebsocketService.subscribe(
          `/topic/notifications/${currentUser.email}`,
          (message) => {
            //const data = response.body;//JSON.parse(response.body);
            console.log("Message received via websocket=====>", message);
            if (message instanceof Array) {
              notification.info({
                message: `You have ${message.length} new Reminders. Check your notifications`,
                duration: 0,
              });
              dispatch({ type: NEW_NOTIFICATIONS_ARRAY, payload: message });
            } else {
              notification.info({
                message: "You have new unread notifications",
              });
              dispatch({ type: NEW_NOTIFICATIONS, payload: message });
            }
          }
        );

        WebsocketService.subscribe(
            `/topic/logout`,
            (message) => {
              console.log("Logout notification received:", message);
              if (message?.userEmail === currentUser.email) {
                setLogoutModalVisible(true); // Show the modal
              }
            }
        );
      });
    }
    return () => {
      WebsocketService.disconnect();
    };
  }, [currentUser]);

  useEffect(() => {
    if (logoutModalVisible) {
      const handlePopState = (e) => {
        e.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
      };

      window.history.pushState(null, '', window.location.pathname);
      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [logoutModalVisible]);

  if (state.loading) {
    return <LoadingIndicator />;
  }

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    localStorage.setItem("collapsed", !sidebarCollapsed);
  };

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('forceLogout');
    dispatch(setAuthenticated(false));
    setLogoutModalVisible(false);
    navigate("/login");
  }

  return (
      <div className="App">
        <div className={logoutModalVisible ? "blur-sm pointer-events-none select-none" : ""}>
          <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/signup" element={<CreateAccount/>}/>
            <Route
                path="/onboarding"
                element={state.authenticated ? <OnboardingPage/> : <Login/>}
            />
            <Route
                element={
                  <div className="mainContainer">
                    <Sidebar
                        sidebarCollapsed={sidebarCollapsed}
                        toggleSidebar={toggleSidebar}
                        currentRoleView={currentRoleView}
                    />
                    <div
                        className="rightContainer bg-[#E8E8FF]"
                        style={{width: sidebarCollapsed && "calc(100vw - 50px)"}}
                    >
                      <div className="col-span-4 ">
                        <TopBar currentRoleView={currentRoleView}/>
                        <div
                            style={{height: "calc(100vh - 100px)", overflow: "auto"}}
                        >
                          <Outlet/>
                        </div>
                      </div>
                    </div>
                  </div>
                }
            >
              <Route
                  path="/"
                  element={state.authenticated ? <LiveNotes/> : <Login/>}
              />
              <Route
                  path="/livenotes"
                  element={state?.authenticated ? <LiveNotes/> : <Login/>}
              />
              <Route
                  path="/dashboard"
                  element={state?.authenticated ? <DashboardArea/> : <Login/>}
              />
              <Route
                  path="/teachersDashboard"
                  element={state?.authenticated ? <DashboardArea/> : <Login/>}
              />
              <Route
                  path="/planner"
                  element={state?.authenticated ? <Planner/> : <Login/>}
              />
              <Route
                  path="/program"
                  element={state?.authenticated ? <Program/> : <Login/>}
              />

              <Route
                  path="/program/:id/*"
                  element={state?.authenticated ? <ProjectDetails/> : <Login/>}
              >
                <Route path="posts" element={<ProjectDetails/>}/>
                <Route path="schedules" element={<ProjectDetails/>}/>
                <Route path="tasks" element={<ProjectDetails/>}/>
                <Route path="chats" element={<ProjectDetails/>}/>
                <Route path="weblinks" element={<ProjectDetails/>}/>
                <Route path="todos" element={<ProjectDetails/>}/>
                <Route path="notes" element={<ProjectDetails/>}/>
              </Route>
              <Route
                  path="/forum"
                  element={state?.authenticated ? <ChatArea/> : <Login/>}
              />
              <Route
                  path="/forum/:chatId"
                  element={state?.authenticated ? <ChatArea/> : <Login/>}
              />
              <Route
                  path="/reward"
                  element={state?.authenticated ? <Reward/> : <Login/>}
              />
              <Route
                  path="/settings/*"
                  element={state?.authenticated ? <Settings/> : <Login/>}
              >
                <Route path="userProfile" element={<UserProfile/>}/>
                <Route path="timezoneSettings" element={<TimezoneSettings/>}/>
                <Route path="securitySettings" element={<SecuritySettings/>}/>
                <Route
                    path="notificationPreferences"
                    element={<NotificationPreferences/>}
                />
                <Route path="privacySettings" element={<PrivacySettings/>}/>
                <Route
                    path="organizationSettings"
                    element={<OrganizationSettings/>}
                />
              </Route>
              <Route
                  path="/administration"
                  element={state?.authenticated ? <Administration/> : <Login/>}
              />
              <Route
                  path="/academic-programs/temp"
                  element={state?.authenticated ? <AcademicPrograms/> : <Login/>}
              />
              <Route
                  path="/academic-programs"
                  element={
                    state?.authenticated ? (
                        <AcademicPrograms market={false}/>
                    ) : (
                        <Login/>
                    )
                  }
              />
              <Route
                  path="/acad-programs/:id"
                  element={
                    state?.authenticated ? <AcademicProgramsDetails/> : <Login/>
                  }
              />
              <Route
                  path="my-requests"
                  element={state?.authenticated ? <Approvals/> : <Login/>}
              />
              <Route
                  path="/marketplace"
                  element={
                    state?.authenticated ? <Programs market={true}/> : <Login/>
                  }
              />
              <Route
                  path="/marketplace/:organizationId"
                  element={<ProgramDetails/>}
              />
              <Route
                  path="/payment"
                  element={state?.authenticated ? <PaymentPage/> : <Login/>}
              />
              <Route
                  path="/marketplace/temp"
                  element={
                    state?.authenticated ? (
                        <AcademicPrograms market={true}/>
                    ) : (
                        <Login/>
                    )
                  }
              />
              <Route
                  path="/marketplace/:id"
                  element={
                    state?.authenticated ? (
                        <AcademicProgramsDetails market={true}/>
                    ) : (
                        <Login/>
                    )
                  }
              />
            </Route>
          </Routes>
        </div>
        {logoutModalVisible && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">
                  Session Update Required
                </h2>
                <p className="text-sm text-gray-600 mb-6">
                  Your role has been updated. Please log out and log in again to continue.
                </p>
                <button
                    className="w-full px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none"
                    onClick={handleLogout}
                >
                  Log Out
                </button>
              </div>
            </div>
        )}
      </div>
  );
};

export default App;
