import React from "react";
import { CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <CheckCircle className="text-green-500" size={64} />
      <h1 className="mt-6 text-2xl font-bold text-gray-800">
        Payment Successful!
      </h1>
      <p className="mt-2 text-gray-600">Thank you for your purchase.</p>
      <button
        onClick={() => navigate("/settings/manageSubscriptions")}
        className="mt-8 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
      >
        View Subscriptions
      </button>
    </div>
  );
};

export default SuccessPage;
