import React, { useState, useEffect, useRef } from "react";
import Modal from "../../components/Planner/Modal";
import {
  Steps,
  Button,
  Input,
  Checkbox,
  message,
  Select,
  Spin,
  Form,
} from "antd";
import { AiOutlineCheck } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Step } = Steps;
const { Option } = Select;

const ProgramModal = ({
  showCreateProgramModal,
  setShowCreateProgramModal,
  program,
  setProgram,
  handleChange,
  handleEditorChange,
  uploadPlugin,
  handleSave,
  currentStep,
  setCurrentStep,
  form,
  Form,
}) => {
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  const [countries, setCountries] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [loadingUniversities, setLoadingUniversities] = useState(false);
  const [otherUniversity, setOtherUniversity] = useState(false);
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [saving, setSaving] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    if (showCreateProgramModal) {
      form.setFieldsValue(program);
    }
  }, [showCreateProgramModal, program, form]);

  useEffect(() => {
    if (program.id) {
      setCompletedSteps([0, 1, 2, 3, 4]);
    } else {
      setCompletedSteps([]);
    }
  }, [program]);

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };

    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryList = data
          .map((country) => ({ name: country.name.common, code: country.cca2 }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryList);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleCountryChange = (value) => {
    handleChange({ target: { name: "country", value } });

    setLoadingUniversities(true);
    fetch(`http://universities.hipolabs.com/search?country=${value}`)
      .then((response) => response.json())
      .then((data) => {
        const universityList = data.map((university) => university.name).sort();
        setUniversities(universityList);
        setLoadingUniversities(false);
      })
      .catch((error) => {
        console.error("Error fetching universities:", error);
        setLoadingUniversities(false);
      });
  };

  const handleUniversityChange = (value) => {
    if (value === "Other") {
      setOtherUniversity(true);
    } else {
      setOtherUniversity(false);
      handleChange({ target: { name: "university", value } });
    }
  };

  const steps = [
    {
      title: "Program Info",
      content: (
        <>
          <Form.Item
            name="programName"
            label="Program Name"
            rules={[{ required: true, message: "Program name is required!" }]}
          >
            <Input
              name="programName"
              value={program.programName}
              onChange={handleChange}
              placeholder="Enter Program Name"
            />
          </Form.Item>
          <Form.Item label="Description">
            <CKEditor
              editor={ClassicEditor}
              config={{ extraPlugins: [uploadPlugin] }}
              data={program.description}
              onChange={(event, editor) => handleEditorChange(editor.getData())}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Additional Info",
      content: (
        <>
          <Form.Item
            label="Duration"
            name="duration"
            rules={[{ required: true, message: "Duration is required!" }]}
          >
            <Input
              name="duration"
              value={program.duration}
              onChange={handleChange}
              placeholder="Enter Duration"
            />
          </Form.Item>
          <Form.Item label="Level" name="level">
            <Input
              name="level"
              value={program.level}
              onChange={handleChange}
              placeholder="Enter Level"
            />
          </Form.Item>
          <Form.Item label="Mode" name="mode">
            <Input
              name="mode"
              value={program.mode}
              onChange={handleChange}
              placeholder="Enter Mode"
            />
          </Form.Item>
        </>
      ),
    },
    // {
    //   title: "Location & Contact",
    //   content: (
    //     <>
    //       <Form.Item
    //         label="Country"
    //         name="country"
    //         rules={[{ required: true, message: "Country is required!" }]}
    //       >
    //         <Select
    //           name="country"
    //           value={program.country}
    //           onChange={handleCountryChange}
    //           placeholder="Select Country"
    //           showSearch
    //           filterOption={(input, option) =>
    //             option.children.toLowerCase().includes(input.toLowerCase())
    //           }
    //         >
    //           {countries.map((country) => (
    //             <Option key={country.code} value={country.name}>
    //               {country.name}
    //             </Option>
    //           ))}
    //         </Select>
    //       </Form.Item>

    //       <Form.Item label="University" name="university">
    //         <Select
    //           name="university"
    //           value={program.university}
    //           onChange={handleUniversityChange}
    //           placeholder="Select University"
    //           showSearch
    //           disabled={!program.country || loadingUniversities}
    //           notFoundContent={
    //             loadingUniversities ? <Spin size="small" /> : null
    //           }
    //         >
    //           {universities.map((uni) => (
    //             <Option key={uni} value={uni}>
    //               {uni}
    //             </Option>
    //           ))}
    //           <Option key="Other" value="Other">
    //             Other
    //           </Option>
    //         </Select>

    //         {otherUniversity && (
    //           <Input
    //             name="university"
    //             value={program.university}
    //             onChange={handleChange}
    //             placeholder="Enter University Name"
    //             style={{ marginTop: "10px" }}
    //           />
    //         )}
    //       </Form.Item>

    //       <Form.Item label="City & Phone">
    //         <div style={{ display: "flex", justifyContent: "space-between" }}>
    //           <Form.Item
    //             name="city"
    //             // rules={[{ required: true, message: "City is required!" }]}
    //             style={{ width: "48%" }}
    //           >
    //             <Select
    //               name="city"
    //               value={program.city}
    //               onChange={(value) =>
    //                 handleChange({ target: { name: "city", value } })
    //               }
    //               placeholder="Select City"
    //               disabled={!program.country || loadingCities}
    //               notFoundContent={loadingCities ? <Spin size="small" /> : null}
    //             >
    //               {cities.map((city) => (
    //                 <Option key={city} value={city}>
    //                   {city}
    //                 </Option>
    //               ))}
    //             </Select>
    //           </Form.Item>

    //           <Form.Item name="phone" style={{ width: "48%" }}>
    //             <PhoneInput
    //               name="phone"
    //               value={program.phone}
    //               onChange={(value) =>
    //                 handleChange({ target: { name: "phone", value } })
    //               }
    //               placeholder="Enter Phone Number"
    //               country="sg"
    //               onlyCountries={["au", "in", "jp", "sg", "us"]}
    //               containerStyle={{ width: "100%" }}
    //               inputStyle={{ width: "100%" }}
    //             />
    //           </Form.Item>
    //         </div>
    //       </Form.Item>
    //     </>
    //   ),
    // },
    // {
    //   title: "Publish",
    //   content: (
    //     <>
    //       <Form.Item name="isPublished" valuePropName="checked">
    //         <Checkbox
    //           name="isPublished"
    //           checked={program.isPublished}
    //           onChange={handleChange}
    //         >
    //           Is Published
    //         </Checkbox>
    //       </Form.Item>
    //       <Form.Item name="isDeleted" valuePropName="checked">
    //         <Checkbox
    //           name="isDeleted"
    //           checked={program.isDeleted}
    //           onChange={handleChange}
    //         >
    //           Is Deleted
    //         </Checkbox>
    //       </Form.Item>
    //     </>
    //   ),
    // },
  ];

  const next = () => {
    form
      .validateFields()
      .then(() => {
        const newCompletedSteps = [...completedSteps, currentStep];
        setCompletedSteps(newCompletedSteps);
        setCurrentStep((prev) => prev + 1);
      })
      .catch(() => {
        message.error("Please fill in the required fields.");
      });
  };

  const prev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const save = async (e, publish) => {
    setSaving(true);
    try {
      await handleSave(e, publish);
    } finally {
      setCompletedSteps([]);
      setSaving(false);
    }
  };

  return (
    <Modal
      showModal={showCreateProgramModal}
      setShowModal={setShowCreateProgramModal}
    >
      <div
        style={{
          padding: "24px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            fontSize: "1.5rem",
            fontWeight: "600",
            marginBottom: "16px",
            color: "#333",
          }}
        >
          Create Program
        </h2>
        <Form
          form={form}
          layout="vertical"
          className="flex flex-col h-[400px] justify-between"
        >
          <Steps
            current={currentStep}
            size="small"
            style={{ marginBottom: "24px" }}
            responsive={false}
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                title={step.title}
                icon={completedSteps.includes(index) && <AiOutlineCheck />}
                onClick={() => {
                  if (completedSteps.includes(index) || currentStep === index) {
                    setCurrentStep(index);
                  }
                }}
                style={{
                  cursor:
                    completedSteps.includes(index) || currentStep === index
                      ? "pointer"
                      : "not-allowed",
                }}
              />
            ))}
          </Steps>

          <div style={{ marginBottom: "24px" }}>
            {steps[currentStep].content}
          </div>

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
          >
            {currentStep > 0 && (
              <Button onClick={prev} style={{ backgroundColor: "#f5f5f5" }}>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={next}>
                Next
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <>
                {saving ? (
                  <Button type="primary">Saving....</Button>
                ) : (
                  <>
                    <Button
                      type="primary"
                      onClick={(event) => save(event, false)}
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "Keep Unpublished & Save"}
                    </Button>
                    <Button
                      type="primary"
                      onClick={(event) => save(event, true)}
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "Save & Publish"}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ProgramModal;
