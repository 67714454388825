import moment from "moment";
import { ACCESS_TOKEN, API_BASE_URL } from "../constantsNew";
import { chatWithAi, get, post } from "../util/APIUtils";
import { createEvent, fetchEvents } from "./calendarActions";
import { message } from "antd";

export const ADD_POST = "ADD_POST";
export const LIST_POSTS = "LIST_POSTS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_EARLIER_POSTS = "FETCH_EARLIER_POSTS";
export const EDIT_POST = "EDIT_POST";

export function addPost(postProp) {
  return function (dispatch, getState) {
    const { currentUser } = getState().users;
    const postData = {
      text: postProp.text,

      userId: currentUser.id,
      postType: postProp.postType,
    };
    if (postProp.file) {
      postData.fileId = postProp.file.id;
    }
    if (postProp.projectId && postProp.projectName) {
      postData.projectId = postProp.projectId;
      postData.projectName = postProp.projectName;
    }

    const headers = new Headers({
      "Content-Type": "application/json",
    });
    if (localStorage.getItem(ACCESS_TOKEN)) {
      headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      );
    }

    fetch(API_BASE_URL + "/post-service/api/posts", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        let transformedData = data;
        if (postProp.transcriptionData) {
          transformedData = {
            ...data,
            message: {
              ...data.message,
              transcriptionData: postProp.transcriptionData,
            },
          };
        }
        if (postProp.projectId) {
          try {
            await post(
              `/userproject-service/api/user-projects/${postProp.projectId}/posts`,
              [{ postId: data.message.id, postType: data.message.postType }]
            );
            console.log("Roster event posted successfully");
          } catch (error) {
            console.error("Error posting roster event:", error);
          }
        }
        dispatch({
          type: ADD_POST,
          payload: transformedData,
        });
      })
      .catch((error) => {
        console.error("Error saving post:", error);
      });
  };
}

const transcribeFile = (id) => {
  const headers = new Headers();

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }
  fetch(API_BASE_URL + `/post-service/api/file-uploads/${id}/transcribe`, {
    method: "POST",
    headers: headers,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    });
};

export function addFileUpload(formData) {
  return function (dispatch, getState) {
    const { currentUser } = getState().users;
    const headers = new Headers();

    if (localStorage.getItem(ACCESS_TOKEN)) {
      headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      );
    }

    fetch(API_BASE_URL + "/post-service/api/file-uploads", {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, data.id);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };
}

export function fetchPost(post) {
  return function (dispatch, getState) {
    const { currentUser } = getState().users;

    const headers = new Headers({
      "Content-Type": "application/json",
    });
    if (localStorage.getItem(ACCESS_TOKEN)) {
      headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      );
    }

    fetch(API_BASE_URL + "/post-service/api/posts?daysBack=1&size=5&page=2", {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: LIST_POSTS,
          payload: data,
        });
      })
      .catch((error) => {
        console.error("Error saving post:", error);
      });
  };
}

export const fetchPostById = (id, setPost) => async (dispatch) => {
  let response;
  let url = `${API_BASE_URL}/post-service/api/posts/${id}`;
  response = await get(url, undefined, true);
  console.log(response);

  if (setPost) setPost(response);
};

export const autoCompleteLabel =
  (
    label,
    setPostLabels,
    setLabelLoading,
    setNewLabel,
    postLabels,
    currentLabels
  ) =>
  async (dispatch) => {
    let response;
    let url = `${API_BASE_URL}/post-service/api/postLabels/search/findByLabel?label=${label}`;
    response = await get(url, undefined, true);
    console.log(response);
    console.log(currentLabels);

    if (setPostLabels && response.length > 0) {
      let filteredLabels = [];
      response.map((r) => {
        let match = currentLabels?.find((l) => l.label === r.label);
        if (match) filteredLabels.push({ ...r, match: true });
        else filteredLabels.push(r);
      });
      setPostLabels(filteredLabels);
      setNewLabel();
    }
    setLabelLoading(false);
  };

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const setAuthenticated = (isAuthenticated) => ({
  type: SET_AUTHENTICATED,
  payload: isAuthenticated,
});

export const messageSent = (message) => ({
  type: MESSAGE_SENT,
  payload: message,
});
export const messageReceived = (message) => ({
  type: MESSAGE_RECEIVED,
  payload: message,
});

export const uploadFileAndTranscribe = async (mp3Blob) => {
  const headers = new Headers();
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const formData = new FormData();
  formData.append("file", mp3Blob);

  const uploadResponse = await fetch(
    `${API_BASE_URL}/post-service/api/file-uploads`,
    {
      method: "POST",
      headers: headers,
      body: formData,
    }
  );

  if (!uploadResponse.ok) {
    const error = await uploadResponse.json();
    throw new Error(error.message || "File upload failed");
  }

  const uploadData = await uploadResponse.json();

  const transcribeResponse = await fetch(
    `${API_BASE_URL}/post-service/api/file-uploads/${uploadData.id}/transcribe`,
    {
      method: "POST",
      headers: headers,
    }
  );

  if (!transcribeResponse.ok) {
    const error = await transcribeResponse.json();
    throw new Error(error.message || "Transcription failed");
  }

  const transcriptionData = await transcribeResponse.json();

  return { uploadData, transcriptionData };
};

const combineDateAndTime = (date, time) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatDateForAPI = (dateObj) => {
    console.log("dateCheck", moment(dateObj).format("YYYY-MM-DD HH:mm:ss"));
    return moment(dateObj).format("YYYY-MM-DDTHH:mm:ss");
  };

  const parseAMPMTime = (timeStr) => {
    const timeParts = timeStr.match(/(\d+):(\d+)\s?(AM|PM)/i);
    if (!timeParts) return timeStr;

    let hours = parseInt(timeParts[1], 10);
    const minutes = timeParts[2];
    const period = timeParts[3].toUpperCase();

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  };

  if (date && time) {
    const formattedTime = parseAMPMTime(time);
    const combinedDateTime = moment.tz(`${date}T${formattedTime}`, timeZone);
    return formatDateForAPI(combinedDateTime);
  } else if (date) {
    const dateObj = moment.tz(date, timeZone);
    return formatDateForAPI(dateObj);
  } else if (time) {
    const today = moment.tz(timeZone).format("YYYY-MM-DD");
    const formattedTime = parseAMPMTime(time);
    const combinedDateTime = moment.tz(`${today}T${formattedTime}`, timeZone);
    return formatDateForAPI(combinedDateTime);
  }

  return null;
};

const getShortDays = (days) => {
  return days.map((day) => {
    if (day.length === 3 && moment(day, "ddd", true).isValid()) {
      return day;
    }
    return moment(day, "dddd").format("ddd");
  });
};

export const sendActivityAction =
  (
    newActivity,
    currentUser,
    projectData,
    combineDateAndTime,
    adjustEndIfNeeded,
    initDate,
    view,
    finalDate
  ) =>
  async (dispatch) => {
    if (!newActivity.trim()) return;

    const userPost = {
      text: newActivity,
      userId: currentUser.id,
      postType: "USER_INPUT",
      projectId: projectData.id,
      projectName: projectData.projectName,
    };

    dispatch(addPost(userPost));

    try {
      const response = await chatWithAi(
        newActivity,
        currentUser.email,
        currentUser.id
      );

      if (
        response?.event?.length > 0 &&
        response?.event[0].response_type !== "TASK" &&
        response?.event[0].response_type !== "TODO" &&
        !response?.event[0].project_id
      ) {
        console.log("Project-->> 1", response);
        const { event } = response;

        const startDateTime = combineDateAndTime(
          event[0].start_date,
          event[0].start_time
        );
        let endDate =
          event[0].end_date || event[0].start_date || event[0].start_time;
        const endDateTime = combineDateAndTime(endDate, event[0].end_time);

        const tempEvent = adjustEndIfNeeded({
          title: event[0].title,
          start: moment(startDateTime).utc().format(),
          end: moment(endDateTime).utc().format(),
          summary: event[0].description || "",
          recurrenceType: event[0]?.freq?.toUpperCase() || null,
          daysOfWeek: getShortDays(event[0]?.daysOfWeek),
          eventType:
            event[0].response_type === "DEADLINE"
              ? "DEADLINE"
              : event[0].response_type === "REMINDER"
              ? "REMINDER"
              : "SCHEDULED_EVENT",
          colorCode:
            event[0].response_type === "DEADLINE"
              ? "#DA3F51"
              : event[0].response_type === "REMINDER"
              ? "#A8B425"
              : "#06CFF1",
        });

        const responseCheck = await get(
          `/calendar-service/api/calendar/events/search/findBySlot?start=${startDateTime}&end=${endDateTime}`
        );

        if (responseCheck && responseCheck.length > 0) {
          const botReply = {
            text: `The event you are trying to create conflicts with the event: ${
              responseCheck[0]?.title
            } scheduled to start at ${moment(
              responseCheck[0]?.startDate
            ).format("Do MMM, YYYY, ddd hh:mm a")} and end at ${moment(
              responseCheck[0]?.endDate
            ).format(
              "Do MMM, YYYY, ddd hh:mm a"
            )}, would you like to continue or change date?`,
            userId: "ai",
            postType: "BOT_REPLY",
          };

          dispatch(addPost(botReply));
          return { conflictEvent: tempEvent };
        } else {
          const botReply = {
            text: response.final_message,
            userId: "ai",
            postType: "BOT_REPLY",
          };
          dispatch(addPost(botReply));
          await dispatch(createEvent(tempEvent, projectData, response));
          dispatch(fetchEvents(initDate, view, finalDate));
        }
      } else if (
        response?.event?.length > 0 &&
        (response?.event[0].response_type === "TASK" ||
          response?.event[0].response_type === "TODO")
      ) {
        console.log("Project-->> 2", response);
        const tempTodo = {
          title: response.event[0].text_message,
          description: response.event[0].description || "",
          dueDate: response.event[0].endDate || null,
          priority: response.event[0].priority || "MEDIUM",
          todoType: "TASK",
        };

        const responseCheck = await post(
          `/userproject-service/api/user-projects/${
            response.event[0].project_id ?? projectData.id
          }/todos`,
          tempTodo
        );

        if (responseCheck) {
          const botReply = {
            text: response.final_message,
            userId: "ai",
            postType: "BOT_REPLY",
          };
          dispatch(addPost(botReply));
        }
      } else if (
        response?.event?.length > 0 &&
        (response?.event[0].response_type !== "TASK" ||
          response?.event[0].response_type !== "TODO") &&
        response?.event[0].project_id
      ) {
        let scheduleId;
        if (!response?.event[0].schedule_id) {
          const resp = await post(
            `/userproject-service/api/user-projects/${response?.event[0].project_id}/schedules`,
            { scheduleName: "My Schedule" }
          );
          scheduleId = resp?.schedules?.find(
            (schedule) => schedule.scheduleName === "My Schedule"
          )?.id;
        } else {
          scheduleId = response.event[0].schedule_id;
        }
        const { event } = response;
        const startDateTime = combineDateAndTime(
          event[0].start_date,
          event[0].start_time
        );
        let endDate =
          event[0].end_date || event[0].start_date || event[0].start_time;
        const endDateTime = combineDateAndTime(endDate, event[0].end_time);

        const eventData = adjustEndIfNeeded({
          title: event[0].title,
          startDate: moment(startDateTime).utc().format(),
          startTime: moment(startDateTime).utc().format(),
          endTime: moment(endDateTime).utc().format(),
          endDate: moment(endDateTime).utc().format(),
          // summary: event[0].description || "",
          recurrenceType: event[0]?.freq?.toUpperCase() || null,
          daysOfWeek: getShortDays(event[0]?.daysOfWeek),
          eventType:
            event[0].response_type === "DEADLINE"
              ? "DEADLINE"
              : event[0].response_type === "REMINDER"
              ? "REMINDER"
              : "SCHEDULED_EVENT",
        });
        const responseCheck = await post(
          `/userproject-service/api/user-projects/${response.event[0].project_id}/schedules/${scheduleId}/events`,
          eventData
        );
        const botReply = {
          text: response.final_message,
          userId: "ai",
          postType: "BOT_REPLY",
        };
        dispatch(addPost(botReply));
      } else if (
        response?.event?.length == 0 &&
        response?.projects?.length > 0
      ) {
        console.log("Project-->> 3", response);
        response.projects.map(
          async (data) =>
            await post("/userproject-service/api/user-projects", {
              projectName: data?.name,
              projectStatus: "BACKLOG",
            })
        );
        const botReply = {
          text: response.final_message,
          userId: "ai",
          postType: "BOT_REPLY",
        };
        dispatch(addPost(botReply));
      } else {
        console.log("Project-->> 4", response);
        console.log("Response===>", response);
        const botReply = {
          text: response.final_message,
          userId: "ai",
          postType: "BOT_REPLY",
        };
        dispatch(addPost(botReply));
      }
    } catch (error) {
      console.error("Failed to send activity:", error);
      throw error;
    }
  };

export const fetchPostsAction = (page) => async (dispatch) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  const token = localStorage.getItem("ACCESS_TOKEN");
  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }

  try {
    const response = await get(`/post-service/api/posts?page=${page}`);

    const data = response;

    const posts = data?.content.sort(
      (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
    );

    dispatch({
      type: FETCH_POSTS,
      payload: posts,
    });

    return { nextPage: page + 1, totalPages: data.totalPages };
  } catch (error) {
    message.error(
      error?.message || "Oops! we were unable to fetch previous messages!"
    );
    console.error("Error fetching posts:", error);
    throw error;
  }
};

export const fetchEarlierPostAction = (page) => async (dispatch) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  const token = localStorage.getItem("ACCESS_TOKEN");
  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }

  try {
    const response = await get(`/post-service/api/posts?page=${page}`);

    const data = response;

    const posts = data?.content.sort(
      (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
    );

    dispatch({
      type: FETCH_EARLIER_POSTS,
      payload: posts,
    });

    return { nextPage: page + 1, totalPages: data.totalPages };
  } catch (error) {
    message.error(
      error?.message || "Oops! we were unable to fetch previous messages!"
    );
    console.error("Error fetching posts:", error);
    throw error;
  }
};

export const getCurrentUserDetailsAction =
  (currentUserId) => async (dispatch) => {
    const headers = new Headers({
      "Content-Type": "application/json",
    });

    const token = localStorage.getItem("ACCESS_TOKEN");
    if (token) {
      headers.append("Authorization", "Bearer " + token);
    }

    try {
      const response = await get(
        `/stridecal-backend/api/app-users/${currentUserId}`
      );

      const data = response;

      if (data?.firstName === null || data?.lastName === null) {
        return true;
      }

      return false;
    } catch (error) {
      console.error("Error fetching userdata in livenotes:", error);
      throw error;
    }
  };

export const fetchProjectDetailsAction = () => async (dispatch) => {
  try {
    const response = await get(
      `/userproject-service/api/user-projects/search/findByName?name=Miscellaneous`
    );

    return response;
  } catch (error) {
    message.error("Error fetching project details.");
    console.error("Error fetching project details:", error);
    throw error;
  }
};

export const fetchTranscriptionDataAction =
  (id, messages, transcribedMessage) => async (dispatch) => {
    try {
      const response = await get(`/post-service/api/transcriptionJobs/${id}`);
      console.log("transcription", response);

      dispatch({
        type: "SET_TRANSCRIBED_MESSAGE",
        payload: { ...transcribedMessage, id: response },
      });
      dispatch({
        type: "SET_AUTO_SCROLL",
        payload: true,
      });

      messages.forEach((message) => {
        if (
          message.transcriptionData &&
          message.transcriptionData.transcribeId ===
            response.jobName.split("transcription-")[1]
        ) {
          dispatch({
            type: EDIT_POST,
            id: message.id,
            payload: {
              ...message,
              transcriptionData: {
                transcribeId: message.transcriptionData.transcribeId,
                transcriptionInProgress: false,
              },
            },
          });

          dispatch(
            addPost({
              text: `Did you say: '${response.results[0]}' ?`,
              userId: "ai",
              postType: "BOT_REPLY",
            })
          );
        }
      });
    } catch (error) {
      console.error("Error fetching transcription data:", error);
    }
  };
