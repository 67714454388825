import React, { useState, useEffect } from "react";
import { Button, Card } from "antd";
import { FaRupeeSign } from "react-icons/fa";
import { get } from "../../util/APIUtils";
import { useNavigate } from "react-router-dom";
import { AcademicProgramCard } from "./academicCard";

export const OrganizationSection = ({ organization, market }) => {
  const [showAll, setShowAll] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchPrograms = async (organizationId, page = 0, size = 2) => {
    try {
      setLoading(true);
      const response = await get(
        `/organization-service/api/academicPrograms/search/findByOrgId?organizationId=${organizationId}&page=${page}&size=${size}`
      );
      setPrograms(response.content || []);
      setTotalElements(response.totalElements);
    } catch (error) {
      console.error("Error fetching programs:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPrograms(organization.id);
  }, [organization.id]);

  return (
    <Card
      title={organization.name}
      className="shadow-lg hover:shadow-xl transition-shadow duration-300"
      onClick={() =>
        navigate(`/marketplace/${organization.id}`, {
          state: {
            organizationName: organization.name,
          },
        })
      }
    >
      <p className="text-gray-600 mb-2">{organization.address}</p>
      {organization.description && (
        <p className="text-gray-700 mb-4">{organization.description}</p>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        {programs.map((program) => (
          <AcademicProgramCard
            key={program.id}
            program={program}
            market={market}
            organizationName={organization.name}
            organizationId={organization.id}
            programs={programs}
            setPrograms={setPrograms}
            // userRole={userRole}
          />
        ))}
      </div>
      {totalElements > 2 && (
        <Button
          type="primary"
          onClick={() => setShowAll(!showAll)}
          className="w-full"
        >
          {showAll ? "Show Less" : "Explore More"}
        </Button>
      )}
    </Card>
    // <div
    //   className="bg-gray-100 rounded-lg p-6 mb-8 cursor-pointer"
    //   onClick={() =>
    //     navigate(`/marketplace/${organization.id}`, {
    //       state: {
    //         organizationName: organization.name,
    //       },
    //     })
    //   }
    // >
    //   <div className="flex justify-between items-center mb-4">
    //     <div>
    //       <h2 className="text-2xl font-bold">{organization.name}</h2>

    //       <h3 className="text-1xl font-bold">{organization?.address ?? ""}</h3>
    //     </div>
    //   </div>
    //   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
    //     {programs.length > 0 ? (
    //       <>
    //         <div>
    //           {organization.description ? (
    //             <p>
    //               pro some Dummy description. Lorem Ipsum Dolores. some Dummy
    //               description. Lorem Ipsum Dolores. some Dummy description.
    //               Lorem Ipsum Dolores.
    //             </p>
    //           ) : (
    //             <p>No Description</p>
    //           )}
    //         </div>
    //         {programs.map((program) => (
    //           <AcademicProgramCard
    //             key={program.id}
    //             program={program}
    //             market={market}
    //             organizationName={organization.name}
    //             organizationId={organization.id}
    //             // userRole={userRole}
    //           />
    //         ))}
    //       </>
    //     ) : (
    //       <>
    //         <div>
    //           {organization.description ? (
    //             <p>
    //               pro some Dummy description. Lorem Ipsum Dolores. some Dummy
    //               description. Lorem Ipsum Dolores. some Dummy description.
    //               Lorem Ipsum Dolores.
    //             </p>
    //           ) : (
    //             <p>No Description</p>
    //           )}
    //           <h3 className="text-lg font-semibold">No Programs</h3>
    //         </div>
    //       </>
    //     )}
    //   </div>
    //   {market ? (
    //     <div className="flex justify-end gap-2">
    //       {totalElements > 4 && (
    //         <div className="text-right">
    //           <Button type="primary" onClick={() => setShowAll(!showAll)}>
    //             {showAll ? "Show Less" : "Explore More"}
    //           </Button>
    //         </div>
    //       )}
    //     </div>
    //   ) : (
    //     <div className="flex items-center">
    //       <FaRupeeSign className="text-xl mr-1" />
    //       <span className="text-xl font-semibold"></span>
    //     </div>
    //   )}
    // </div>
  );
};
