import React from "react";
import { Loader2 } from "lucide-react";

const Loader = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <Loader2 className="animate-spin text-blue-600" size={48} />
      <p className="mt-4 text-lg font-semibold text-gray-700">
        Processing your payment...
      </p>
    </div>
  );
};

export default Loader;
