import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Card, Dropdown } from "antd";
import { FaEdit, FaTrash, FaToggleOn, FaToggleOff } from "react-icons/fa";
import { EllipsisOutlined } from "@ant-design/icons";
import ContentImg from "../../assets/imagePlaceholder.png";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../util/APIUtils";
import { useSelector } from "react-redux";
import { Confirm } from "react-st-modal";

export const AcademicProgramCard = ({
  program,
  market,
  organizationName,
  organizationId,
  programs,
  setPrograms,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);
  const goToDetails = (p) => {
    if (market) {
      navigate(`/marketplace/${p.id}`);
    } else {
      navigate(`/acad-programs/${p.id}`);
    }
  };
  const menuItems = [
    { key: "1", icon: <FaEdit />, label: "Edit" },
    { key: "2", icon: <FaTrash />, label: "Delete" },
    {
      key: "3",
      icon: program.isPublished ? <FaToggleOff /> : <FaToggleOn />,
      label: program.isPublished ? "Unpublish" : "Publish",
    },
  ];

  const handleSubscribe = async (e, program) => {
    e.stopPropagation();
    try {
      const response = await get(
        `/organization-service/api/academicPrograms/${program.id}/paidSubscriptionStatus`
      );
      if (response.subscriptionStatus !== "NOT_SUBSCRIBED") {
        const response = await post(
          `/organization-service/api/academicPrograms/${program.id}/subscribe`
        );
        if (response) {
          navigate(`/marketplace/${response.organization.id}/`);
        }
      } else {
        navigate("/payment", {
          state: {
            organizationName: organizationName,
            academicProgram: program,
            organizationId,
          },
        });
      }
    } catch {}
  };

  const handleCancelSub = async (e, programId) => {
    e.stopPropagation();
    const ConfirmCancel = await Confirm(
      `Are you sure you want to cancel your subscription?`
      // You will still have access to your academic program for the duration of the already paid period.
    );

    if (ConfirmCancel) {
      try {
        await post(
          `/organization-service/api/academicPrograms/${programId}/unsubscribe`
        );
        const updatedPrograms = programs.map((program) =>
          program.id === programId
            ? {
                ...program,
                subscriptions: program.subscriptions.filter(
                  (sub) => sub.userName !== currentUser.email
                ),
              }
            : program
        );
        setPrograms(updatedPrograms);
      } catch (error) {
        console.error("Error cancelling subscription:", error);
      }
    }
  };

  return (
    <Card
      cover={
        <img
          src={program.image || ContentImg}
          alt={program.name}
          className="h-32 object-cover"
        />
        // <img
        //   alt={program.name}
        //   src={program.image}
        //   className="h-32 object-cover"
        // />
      }
      className="h-full flex flex-col justify-between hover:shadow-xl transition-shadow duration-300"
      onClick={() => goToDetails(program)}
    >
      <h3 className="text-lg font-semibold mb-2">{program.programName}</h3>
      <Button
        type="primary"
        className="w-full mt-auto"
        onClick={(e) =>
          program.academicProgramSubscriptions?.find(
            (ps) =>
              ps.userName === currentUser.email &&
              (ps.active == null || ps.active)
          )
            ? handleCancelSub(e, program.id)
            : handleSubscribe(e, program)
        }
        // disabled={program.academicProgramSubscriptions?.find(
        //   (ps) =>
        //     ps.userName === currentUser.email &&
        //     (ps.active == null || ps.active)
        // )}
      >
        {program.academicProgramSubscriptions?.find(
          (ps) =>
            ps.userName === currentUser.email &&
            (ps.active == null || ps.active)
        )
          ? "Unsubscribe"
          : "Subscribe"}
      </Button>
    </Card>
    // <div
    //   className="bg-white rounded-lg shadow-md p-4 flex flex-col transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg relative"
    //   onClick={() => goToDetails(program)}
    // >
    //   <img
    //     src={program.image || ContentImg}
    //     alt={program.name}
    //     className="w-full h-24 object-cover rounded-md mb-2"
    //   />
    //   <h3 className="text-lg font-semibold">{program.programName}</h3>
    //   <p
    //     className="text-sm text-gray-600 rte whitespace-normal text-ellipsis"
    //     style={{
    //       display: "-webkit-box",
    //       WebkitLineClamp: 3,
    //       WebkitBoxOrient: "vertical",
    //     }}
    //     dangerouslySetInnerHTML={{ __html: program.description }}
    //   ></p>
    //   <div className="px-2 mt-2 w-full">
    //     <Button
    //       className="w-full"
    //       onClick={(e) => handleSubscribe(e, program)}
    //       disabled={program.academicProgramSubscriptions?.find(
    //         (ps) =>
    //           ps.userName === currentUser.email &&
    //           (ps.active == null || ps.active)
    //       )}
    //     >
    //       {program.academicProgramSubscriptions?.find(
    //         (ps) =>
    //           ps.userName === currentUser.email &&
    //           (ps.active == null || ps.active)
    //       )
    //         ? "Unsubscribe"
    //         : "Subscribe"}
    //     </Button>
    //   </div>
    //   {!market && (
    //     <div className="absolute top-2 right-2">
    //       <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
    //         <Button icon={<EllipsisOutlined />} size="small" />
    //       </Dropdown>
    //     </div>
    //   )}
    // </div>
  );
};
