import { get, post, put, del } from "../util/APIUtils";
import { UPDATE_PROGRAM } from "./academicProgramActions";

export const FETCH_SCHEDULES = "FETCH_SCHEDULES";
export const SAVE_SCHEDULE = "SAVE_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const UPDATE_PROGRAM_SCHEDULE = "UPDATE_PROGRAM_SCHEDULE";
export const FETCH_TASKS = "FETCH_TASKS";
export const SAVE_TASK = "SAVE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const UPDATE_SELECTED_PROGRAM_SCHEDULE =
  "UPDATE_SELECTED_PROGRAM_SCHEDULE";

export const fetchSchedules = () => {
  return async (dispatch) => {
    const response = await get("/organization-service/api/schedules");
    dispatch({ type: FETCH_SCHEDULES, payload: response });
  };
};

export const saveSchedule = (scheduleData) => {
  return async (dispatch) => {
    const response = await post(
      "/organization-service/api/schedules",
      scheduleData
    );
    dispatch({ type: SAVE_SCHEDULE, payload: response });
    return response;
  };
};

export const updateSchedule = (id, scheduleData) => {
  return async (dispatch) => {
    const response = await put(
      `/organization-service/api/schedules/${id}`,
      scheduleData
    );
    dispatch({ type: UPDATE_SCHEDULE, payload: response });
  };
};

export const saveEventToSchedule = (
  id,
  eventData,
  setSavingEvent,
  setEvents,
  programId
) => {
  return async (dispatch) => {
    const response = await put(
      `/organization-service/api/schedules/${id}/events`,
      eventData
    );
    // setSavingEventSchedule(false)
    setSavingEvent(false);
    setEvents((prev) => [...response.rosterCalendarEvents]);
    // dispatch({ type: UPDATE_SCHEDULE, payload: response });
    // dispatch({ type: UPDATE_PROGRAM_SCHEDULE, payload: {schedule: response,programId} });
    dispatch({ type: UPDATE_SELECTED_PROGRAM_SCHEDULE, payload: response });
  };
};

export const deleteSchedule = (id) => {
  return async (dispatch) => {
    await del(`/organization-service/api/schedules/${id}`);
    dispatch({ type: DELETE_SCHEDULE, payload: id });
  };
};
