import React, { useState } from "react";
import LoginBg from "../assets/loginBg.png";
import CameraIcon from "../assets/camera.svg";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN, API_BASE_URL } from "../constantsNew";
const OnboardingPage = ({ handleFile }) => {
  const { users } = useSelector((state) => state);
  const currentUser = users?.currentUser;
  console.log(currentUser);
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const [userDetails, setUserDetails] = useState({
    firstName: currentUser?.firstName ? currentUser?.firstName : "",
    lastName: currentUser?.lastName ? currentUser?.lastName : "",
    email: currentUser?.email ? currentUser?.email : "",
    phone: currentUser?.phone ? currentUser?.phone : "",
    gender: currentUser?.gender ? currentUser?.gender : "",
    birthday: currentUser?.birthday ? currentUser?.birthday : "",
    type: "",
    school: "",
  });

  // Programmatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Call the handleFile function directly with the file
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Directly call handleFile with the file
      handleFile(file);
    }
  };
  const onBoardUser = () => {
    const onboardRequest = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      // email:userDetails.email,
      phone: userDetails.phone,
      gender: userDetails.gender,
      birthday: userDetails.birthday,
    };

    const headers = new Headers();

    if (localStorage.getItem(ACCESS_TOKEN)) {
      headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      );
      headers.append("Content-Type", "application/json");
    }

    fetch(
      API_BASE_URL + `/stridecal-backend/api/app-users/${currentUser?.id}`,
      {
        method: "PATCH",
        body: JSON.stringify(onboardRequest),
        headers: headers, // Content-Type not set manually
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data, data.id);
        //transcribe data
        navigate("/");
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };
  return (
    <div
      style={{ backgroundImage: `url(${LoginBg})` }}
      className="flex items-center h-[100vh] bg-cover justify-center "
    >
      <div className="px-12 py-6 w-[800px] bg-white rounded-lg">
        <h4 className="font-[600] text-start text-[1.3rem]">
          Your Information
        </h4>
        <div>
          <div className="grid grid-cols-11 mt-4 mb-2 items-center justify-start gap-1">
            <h5 className="col-span-2">Personal Details</h5>
            <hr className="bg-gray-400 col-span-9 w-full" />
          </div>
          <div className="grid grid-cols-5 mt-6 gap-8 grid-flow-col">
            <div className="col-span-1 flex items-center justify-start flex-col gap-3 ">
              <div
                onClick={handleClick}
                className="bg-gray-200 cursor-pointer flex items-center justify-center  w-[70px] h-[70px] rounded-full"
              >
                <img src={CameraIcon} />
              </div>
              <p className="text-[#4379EE]">Upload Image</p>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }} // Make the file input element invisible
                accept="audio/*, video/*, image/*" // Optionally, restrict file types
              />
            </div>
            <div className="col-span-2">
              <div className="">
                <label className="mb-2">First Name</label>
                <input
                  value={userDetails?.firstName}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      firstName: e.target.value,
                    })
                  }
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="text"
                  placeholder="Enter your First Name"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2">Your Email</label>
                <input
                  value={userDetails?.email}
                  disabled
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="text"
                  placeholder="Enter your Email"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2">Date of Birth</label>
                <input
                  value={userDetails?.birthday}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, birthday: e.target.value })
                  }
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="date"
                  placeholder="Enter your birthdate"
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="">
                <label className="mb-2">Last Name</label>
                <input
                  value={userDetails?.lastName}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, lastName: e.target.value })
                  }
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="text"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2">Phone Number</label>
                <input
                  value={userDetails?.phone}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, phone: e.target.value })
                  }
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="text"
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="mt-6">
                <label className="mb-2">Gender</label>
                <select
                  value={userDetails?.gender}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, gender: e.target.value })
                  }
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="text"
                  placeholder="Enter your birthdate"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other"> Other</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-11 mt-4 mb-2 items-center justify-start gap-1">
            <h5 className="col-span-3">EDUCATIONAL DETAILS</h5>
            <hr className="bg-gray-400 col-span-8 w-full" />
          </div>
          <div className="grid grid-cols-5 mt-2 gap-8 grid-flow-col">
            <div className="col-span-1"></div>
            <div className="col-span-2">
              <div className="">
                <label className="mb-2">I am</label>
                <select
                  value={userDetails?.gender}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, gender: e.target.value })
                  }
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="text"
                  placeholder="Enter your birthdate"
                >
                  <option value="student">Student</option>
                  <option value="teacher">Teacher</option>
                  <option value="parent"> Parent</option>
                </select>
              </div>
            </div>
            <div className="col-span-2">
              <div className="">
                <label className="mb-2">School/Institute</label>
                <input
                  className="py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100"
                  type="text"
                  placeholder="Enter your last name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-6 items-center justify-between">
          <div className="text-[0.6rem]">
            <h3>
              By signing up you are agreeing to the{" "}
              <span className="text-[#5A8CFF]">Terms & Conditions</span>{" "}
            </h3>
            <p>
              You will shortly receive an email once your account is validated
              by your admin.
            </p>
          </div>
          <button
            onClick={onBoardUser}
            className="py-3 text-[0.8rem] px-16 rounded-lg bg-[#4880FF] text-white"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
