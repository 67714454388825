import { notification } from "antd";
import { get, post, put, del } from "../util/APIUtils";

export const FETCH_EVENTS = "FETCH_EVENTS";
export const FETCH_PLANS = "FETCH_PLANS";
export const CREATE_EVENT = "CREATE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const FETCH_COLOR_SCHEME = "FETCH_COLOR_SCHEME";

export const fetchColorScheme = () => {
  return async (dispatch) => {
    try {
      const response = await get("/calendar-service/api/colorschemes");
      dispatch({ type: FETCH_COLOR_SCHEME, payload: response });
    } catch (error) {
      console.error("Failed to fetch color scheme", error);
    }
  };
};

export const fetchEvents = (date, view, endDate) => {
  return async (dispatch) => {
    let response;
    if (view == "month") {
      response = await get(
        `/calendar-service/api/calendar/events?startDate=${date}&&endDate=${endDate}`
      );
    } else {
      response = await get(
        `/calendar-service/api/calendar/events?date=${endDate}&view=${view}`
      );
    }
    if (Array.isArray(response)) {
      dispatch({ type: FETCH_EVENTS, payload: response });
    } else {
      notification.error({
        message: "Login Failed",
        description: "Oops! Something went wrong. Please try again!",
        duration: 3, // Duration in seconds
      });
    }
  };
};

export const fetchPlans = () => {
  return async (dispatch) => {
    const response = await get(`/calendar-service/api/plans/search`);
    console.log(response);

    dispatch({ type: FETCH_PLANS, payload: response });
  };
};

export const fetchEvent = (url, callback, completeUrl) => {
  return async (dispatch) => {
    let response;
    if (completeUrl) {
      response = await get(url, undefined, completeUrl);
      console.log(response);
    } else {
      response = await get(url);
      console.log(response);
    }
    if (callback) callback(response);
  };
};

export const createEvent = (eventData, projectData, resp) => {
  return async (dispatch) => {
    const response = await post(
      "/calendar-service/api/calendar/events",
      eventData
    );
    if (projectData) {
      await post(
        `/userproject-service/api/user-projects/${
          resp.event[0].project_id ?? projectData.id
        }/events`,
        response[0]
      );
    }
    dispatch({ type: CREATE_EVENT, payload: response });
    return response;
  };
};

export const updateEvent = (id, eventData, setUpdating, setUpdatePlans) => {
  return async (dispatch) => {
    const response = await put(
      `/calendar-service/api/calendar/events/${id}`,
      eventData
    );
    if (setUpdating && setUpdatePlans) {
      setUpdating(false);
      setUpdatePlans(true);
    }
    dispatch({ type: UPDATE_EVENT, payload: response });
  };
};

export const deleteEvent = (id) => {
  return async (dispatch) => {
    await del(`/calendar-service/api/calendar/delete/${id}`);
    dispatch({ type: DELETE_EVENT, id });
  };
};

export const fetchEventById = (id, setEventDetails) => {
  return async (dispatch) => {
    let response;

    response = await get(`/calendar-service/api/calendar/events/${id}`);
    console.log("Event Details", response);
    setEventDetails({
      id: response.id,
      title: response.title,
      start: response.startDate,
      end: response.endDate ? response.endDate : response.startDate,
      colorCode: response.colorCode,
      summary: response.summary,
      eventType: response.eventType,
    });
    // if (Array.isArray(response)) {
    //   dispatch({ type: FETCH_EVENTS, payload: response });
    // } else {
    //   notification.error({
    //     message: "Login Failed",
    //     description: "Oops! Something went wrong. Please try again!",
    //     duration: 3, // Duration in seconds
    //   });
    // }
  };
};
