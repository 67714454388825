import React from "react";
import { CheckCircle, MessageCircleWarningIcon } from "lucide-react";

const FailPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <MessageCircleWarningIcon className="text-green-500" size={64} />
      <h1 className="mt-6 text-2xl font-bold text-gray-800">Payment Failed!</h1>
      <p className="mt-2 text-gray-600">
        The payment for the program failed. Please try again!
      </p>
      <button
        onClick={() => window.location.reload()}
        className="mt-8 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
      >
        Return to Payment Page
      </button>
    </div>
  );
};

export default FailPage;
