import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Pagination, Skeleton } from "antd";
import { get, post } from "../../util/APIUtils";
import ContentImg from "../../assets/imagePlaceholder.png";
import { FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Confirm } from "react-st-modal";

export const ProgramDetails = () => {
  const { organizationId, organizationName } = useParams(); // Get organization ID from route params
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [programsList, setProgramsList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const currentUser = useSelector((state) => state.users.currentUser);

  const pageSize = 20;
  const navigate = useNavigate();

  const fetchOrganizationDetails = async (orgId) => {
    try {
      setLoading(true);
      const response = await get(
        `/organization-service/api/organizations/${orgId}`
      );
      setOrganization(response);
    } catch (error) {
      console.error("Error fetching organization details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPrograms = async (organizationId, size = 20) => {
    try {
      setLoading(true);
      const response = await get(
        `/organization-service/api/academicPrograms/search/findByOrgId?organizationId=${organizationId}&page=${
          page - 1
        }&size=${size}`
      );
      setProgramsList(response.content || []);
      setTotalRecords(response.totalElements);
    } catch (error) {
      console.error("Error fetching programs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (e, program) => {
    e.stopPropagation();
    try {
      const response = await get(
        `/organization-service/api/academicPrograms/${program.id}/paidSubscriptionStatus`
      );
      if (response.subscriptionStatus !== "NOT_SUBSCRIBED") {
        const response = await post(
          `/organization-service/api/academicPrograms/${program.id}/subscribe`
        );
        if (response) {
          navigate(`/marketplace/${response.organization.id}/`);
        }
      } else {
        navigate("/payment", {
          state: {
            organizationName: organizationName,
            academicProgram: program,
            organizationId,
          },
        });
      }
    } catch {}
  };

  // console.log("Test---->>>", organization, organization.id);
  useEffect(() => {
    fetchPrograms(organizationId);
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      fetchOrganizationDetails(organizationId);
    }
  }, [organizationId]);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Skeleton active />
      </div>
    );
  }

  if (!organization) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-xl font-bold">Organization not found</h2>
        <Button type="primary" onClick={() => navigate("marketPlace")}>
          Go Back
        </Button>
      </div>
    );
  }

  const handleUnsubscribe = async (e, record) => {
    e.stopPropagation();
    const confirmCancel = await Confirm(
      `Are you sure you want to cancel your subscription?`
      // You will still have access to your academic program for the duration of the already paid period.
    );

    if (confirmCancel) {
      try {
        await post(
          `/organization-service/api/academicPrograms/${record.id}/unsubscribe`
        );

        fetchPrograms(organizationId);
      } catch (error) {
        console.error("Error approving request:", error);
      }
    }
  };

  console.log("Dy--->>>", programsList);

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Organization Title and Address */}
      <div className="bg-gray-100 rounded-lg p-6 mb-6">
        <h1 className="text-2xl font-bold">{organization.name}</h1>
        {organization.address && (
          <p className="text-lg text-gray-600">{organization.address}</p>
        )}
        {organization.price && (
          <div className="flex items-center mt-2">
            <FaRupeeSign className="text-xl mr-1" />
            <span className="text-xl font-semibold">
              {organization.price.toLocaleString("en-IN")}
            </span>
          </div>
        )}
      </div>

      <div className="mt-8 mb-8">
        <Button type="default" onClick={() => navigate("/marketplace")}>
          Back to Organizations
        </Button>
      </div>

      {/* Programs Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {programsList && programsList.length > 0 ? (
          programsList.map((program) => (
            <div
              key={program.id}
              className="bg-white rounded-lg shadow-md p-4 flex flex-col transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
              onClick={() => navigate(`/acad-programs/${program.id}`)}
            >
              <img
                src={program.image || ContentImg}
                alt={program.programName}
                className="w-full h-24 object-cover rounded-md mb-2"
              />
              <h3 className="text-lg font-semibold">{program.programName}</h3>
              <p className="text-sm text-gray-600">{program.address}</p>
              <p className="text-sm text-gray-600">
                Start Date: {program.date}
              </p>
              {/* <p className="text-sm text-gray-600">
                {program.isPublished ? "Published" : "Unpublished"}
              </p> */}
              <div className="px-2 mt-2 w-full">
                <Button
                  className="w-full"
                  onClick={(e) => {
                    program.academicProgramSubscriptions?.find(
                      (ps) =>
                        ps.userName === currentUser.email &&
                        (ps.active == null || ps.active)
                    )
                      ? handleUnsubscribe(e, program)
                      : handleSubscribe(e, program);
                  }}
                >
                  {program.academicProgramSubscriptions?.find(
                    (ps) =>
                      ps.userName === currentUser.email &&
                      (ps.active == null || ps.active)
                  )
                    ? "Unsubscribe"
                    : "Subscribe"}
                </Button>
              </div>
            </div>
          ))
        ) : (
          <h3 className="text-lg font-semibold">No programs available</h3>
        )}
      </div>

      <Pagination
        className="mt-3"
        current={page}
        total={totalRecords}
        pageSize={pageSize}
        onChange={(page) => {
          setPage(page);
          fetchPrograms(null, page, pageSize); // Pass current page to the function
        }}
        showSizeChanger={false}
      />
    </div>
  );
};
